import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import {
    FiUser,
    FiSmartphone,
    FiMail,
    FiCheckCircle,
    FiAlertCircle
} from 'react-icons/fi';
import axios from 'axios';
import './CustomerRegistrationPage.css';

const CustomerRegistrationPage = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        whatsappNumber: '',
        email: '',
        countryCode: '+91',
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const countryCodes = [
        { code: '+91', label: '+91' },
        { code: '+1', label: '+1' },
        { code: '+44', label: '+44' },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');

        const fullPhoneNumber = `${formData.countryCode}${formData.whatsappNumber}`;

        try {
            const response = await axios.post('/shopify/public/register-customer', {
                firstName: formData.firstName,
                lastName: formData.lastName,
                phone: fullPhoneNumber,
                email: formData.email,
            });

            setSuccess('Your exclusive discount code is on its way!');
            setFormData({
                firstName: '',
                lastName: '',
                whatsappNumber: '',
                email: '',
                countryCode: '+91',
            });
        } catch (err) {
            setError(err.response?.data?.message || 'Something went wrong. Try again!');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="customer-registration-page">
            <div className="particles-background"></div>
            <Container className="container-wrapper">
                <div className="registration-container">
                    <div className="exclusive-ribbon">Exclusive Offer</div>
                    <div className="registration-header">
                        <h2>
                            <span className="gradient-text">13% Additional</span>
                            <span className="gradient-text">Discount</span>
                        </h2>
                        <p className="subtitle">For You</p>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={12} md={6} className="mb-4">
                                <Form.Group className="floating-label" controlId="formFirstName">
                                    <div className="input-icon">
                                        <FiUser className="icon" />
                                    </div>
                                    <Form.Control
                                        type="text"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        placeholder="First Name"
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Form.Group className="floating-label" controlId="formLastName">
                                    <div className="input-icon">
                                        <FiUser className="icon" />
                                    </div>
                                    <Form.Control
                                        type="text"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        placeholder="Last Name"
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} className="mb-4">
                                <Form.Group className="floating-label" controlId="formWhatsappNumber">
                                    <div className="phone-input">
                                        <Form.Select
                                            name="countryCode"
                                            value={formData.countryCode}
                                            onChange={handleChange}
                                            className="country-code-select"
                                        >
                                            {countryCodes.map((cc) => (
                                                <option key={cc.code} value={cc.code}>
                                                    {cc.label}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control
                                            type="tel"
                                            name="whatsappNumber"
                                            value={formData.whatsappNumber}
                                            onChange={handleChange}
                                            placeholder="WhatsApp"
                                            pattern="[0-9]{10}"
                                            required
                                            className="phone-number-input"
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} className="mb-4">
                                <Form.Group className="floating-label" controlId="formEmail">
                                    <div className="input-icon">
                                        <FiMail className="icon" />
                                    </div>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="Email Address"
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Button type="submit" className="btn-register" disabled={loading}>
                            {loading ? (
                                <>
                                    <span className="spinner"></span>
                                    Processing...
                                </>
                            ) : (
                                'Claim My Exclusive Discount'
                            )}
                        </Button>
                    </Form>

                    {success && (
                        <div className="success-message">
                            <FiCheckCircle className="status-icon" />
                            {success}
                        </div>
                    )}
                    {error && (
                        <div className="error-message">
                            <FiAlertCircle className="status-icon" />
                            {error}
                        </div>
                    )}
                </div>
            </Container>
        </div>
    );
};

export default CustomerRegistrationPage;