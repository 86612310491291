/* react_frontend/src/pages/LabourEfficiencyPage.js */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../LabourEfficiencyPage.css';

const LabourEfficiencyPage = () => {
    const [labourData, setLabourData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [sortBy, setSortBy] = useState('efficiency'); // efficiency, output, hours

    useEffect(() => {
        fetchLabourData();
    }, [sortBy]);

    const fetchLabourData = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.get('/labour/efficiency', { params: { sortBy }, withCredentials: true });
            console.log('API Response:', response.data); // Debug log
            // Ensure response.data is an array; fallback to empty array if not
            const data = Array.isArray(response.data) ? response.data : [];
            setLabourData(data);
        } catch (err) {
            setError('Failed to rank guild members: ' + (err.response?.data?.message || err.message));
            setLabourData([]); // Reset to empty array on error
        } finally {
            setLoading(false);
        }
    };

    const handleSort = (field) => setSortBy(field);

    return (
        <div className="labour-efficiency-page iron-star-guild">
            <div className="forge-star">
                <h3>Guild of the Iron Star</h3>
            </div>

            {loading ? (
                <div className="guild-loading">
                    <div className="gear-spinner"></div>
                    <span>Ranking the Guild...</span>
                </div>
            ) : error ? (
                <div className="guild-error">{error}</div>
            ) : labourData.length === 0 ? (
                <div className="guild-empty">No guild members ranked yet.</div>
            ) : (
                <>
                    <div className="sort-controls">
                        <button onClick={() => handleSort('efficiency')}>Efficiency</button>
                        <button onClick={() => handleSort('output')}>Output</button>
                        <button onClick={() => handleSort('hours')}>Hours</button>
                    </div>

                    <section className="guild-section">
                        <h4>Guild Rankings</h4>
                        <div className="badge-list">
                            {labourData.map(worker => (
                                <div key={worker.id} className={`guild-badge ${worker.rank === 1 ? 'top-star' : worker.rank === labourData.length ? 'low-star' : ''}`}>
                                    <div className="star-rank">{worker.rank}</div>
                                    <span className="badge-name">{worker.name}</span>
                                    <div className="efficiency-bar" style={{ width: `${worker.efficiency}%` }}></div>
                                    <span className="badge-efficiency">{worker.efficiency}% Efficiency</span>
                                    <span className="badge-output">Output: {worker.output}</span>
                                    <span className="badge-hours">Hours: {worker.hours}</span>
                                </div>
                            ))}
                        </div>
                    </section>

                    <section className="guild-section">
                        <h4>Forge Leaderboard</h4>
                        <div className="leaderboard">
                            <div className="leader-card top-leader">
                                <span>Top Star: {labourData[0]?.name || 'N/A'}</span>
                                <span>{labourData[0]?.efficiency || 0}%</span>
                            </div>
                            <div className="leader-card low-leader">
                                <span>Low Star: {labourData[labourData.length - 1]?.name || 'N/A'}</span>
                                <span>{labourData[labourData.length - 1]?.efficiency || 0}%</span>
                            </div>
                        </div>
                    </section>
                </>
            )}
        </div>
    );
};

export default LabourEfficiencyPage;