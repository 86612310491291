// react_frontend/src/pages/HomePage.js
import React from 'react';
import { useAuth } from '../context/AuthContext';
import './HomePage.css';

const HomePage = () => {
    const { user } = useAuth();

    return (
        <div className="home-page cosmic-hub">
            <div className="hub-header">
                <h3>Healthfab</h3>
            </div>
            <section className="welcome-section">
                <h4>Welcome, {user?.name || 'Unknown Voyager'}!</h4>
                <p>
                    Your journey begins here. Manage Productions, Inventory, shipments, orders, and more. Stay Tuned.
                </p>
            </section>
        </div>
    );
};

export default HomePage;