/* react_frontend/src/components/ShipmentModal.js */
import React from 'react';
import { Form, Accordion } from 'react-bootstrap';
import { formatDate, daysDifference } from '../utils/dateUtils';

const ShipmentModal = ({
  show,
  handleClose,
  shipmentDetails,
  newComment,
  setNewComment,
  handleAddComment,
}) => (
  <div className={`radar-modal ${show ? 'active' : ''}`}>
    <div className="radar-overlay" onClick={handleClose}></div>
    <div className="radar-content">
      <div className="radar-sweep"></div>
      <button className="close-btn" onClick={handleClose}>✕</button>
      <h2 className="radar-title">Shipment Details</h2>
      <div className="radar-body">
        {shipmentDetails ? (
          <>
            <section className="flight-section">
              <h3>Order Information</h3>
              <div className="info-panel">
                <p><strong>Order No:</strong> {shipmentDetails.order_details?.order_number ?? 'N/A'}</p>
                <p><strong>Amount:</strong> {shipmentDetails.order_details?.order_type ?? 'N/A'} {shipmentDetails.order_details?.net_payment ?? 'N/A'}</p>
                <p><strong>Tracking No:</strong> {shipmentDetails.awb_no}</p>
                <p>
                  <strong>Courier:</strong>{' '}
                  <a href={`https://healthfab.ithinklogistics.co.in/postship/tracking/${shipmentDetails.awb_no}`} target="_blank" rel="noreferrer">
                    {shipmentDetails.logistic}
                  </a>
                </p>
                <p><strong>Status:</strong> {shipmentDetails.current_status}</p>
                <p><strong>Last Scan:</strong> {shipmentDetails.last_scan_details?.remark ?? 'N/A'}</p>
                <p><strong>Days:</strong> {daysDifference(shipmentDetails)} Days</p>
              </div>
            </section>

            <section className="flight-section">
              <h3>Customer Details</h3>
              <div className="info-panel">
                <p><strong>Name:</strong> {shipmentDetails.customer_details?.customer_name ?? 'N/A'}</p>
                <p><strong>Contact:</strong> {shipmentDetails.customer_details?.customer_mobile ?? 'N/A'}</p>
                <p><strong>ETA:</strong> {formatDate(shipmentDetails.expected_delivery_date)}</p>
                <p><strong>Destination:</strong> {shipmentDetails.customer_details?.customer_address1 ?? 'N/A'} {shipmentDetails.customer_details?.customer_city ?? ''}</p>
              </div>
            </section>

            <section className="flight-section">
              <h3>Tracking Details</h3>
              <Accordion>
                {shipmentDetails.scan_details?.map((scan, index) => (
                  <Accordion.Item key={index} eventKey={index} className="path-item">
                    <Accordion.Header>{scan.status} - {formatDate(scan.status_date_time)}</Accordion.Header>
                    <Accordion.Body>
                      <p><strong>Location:</strong> {scan.status_location || 'N/A'}</p>
                      <p><strong>Remark:</strong> {scan.status_remark || 'N/A'}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                )) || <p>No tracking details available.</p>}
              </Accordion>
            </section>

            <section className="flight-section">
              <h3>Comments</h3>
              <div className="comment-feed">
                {shipmentDetails.comments?.map((comment, index) => (
                  <div key={index} className="comment-bubble">
                    <p><strong>{comment.commenter_name}:</strong> {comment.comment_text}</p>
                    <span>{formatDate(comment.comment_date_time)}</span>
                  </div>
                )) || <p>No comments yet.</p>}
              </div>
              <Form.Group className="comment-input">
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  placeholder="Add comments..."
                />
                <button className="send-btn" onClick={handleAddComment}>Add Comment</button>
              </Form.Group>
            </section>
          </>
        ) : (
          <p>shipment data unavailable.</p>
        )}
      </div>
    </div>
  </div>
);

export default ShipmentModal;