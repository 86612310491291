// react_frontend/src/pages/SettingsPage.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useTheme } from '../context/ThemeContext';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './SettingsPage.css';

const SettingsPage = () => {
    const { user } = useAuth();
    const { theme, updateTheme } = useTheme();
    const [shopifyStore, setShopifyStore] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [stores, setStores] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const location = useLocation();

    const [newUser, setNewUser] = useState({ email: '', phoneNumber: '', name: '', role: 'member' });
    const [userError, setUserError] = useState('');
    const [userSuccess, setUserSuccess] = useState('');

    useEffect(() => {
        const fetchStoresAndTeam = async () => {
            try {
                const [storesResponse, teamResponse] = await Promise.all([
                    axios.get('/shopify/stores', { withCredentials: true }),
                    axios.get('/team-members', { withCredentials: true })
                ]);
                setStores(storesResponse.data.stores);
                setTeamMembers(teamResponse.data.teamMembers);
            } catch (err) {
                console.error('Error fetching data:', err);
            }
        };
        fetchStoresAndTeam();

        const params = new URLSearchParams(location.search);
        const connectedStore = params.get('storeConnected');
        if (connectedStore) {
            setSuccess(`Shopify realm ${connectedStore} forged successfully!`);
            fetchStoresAndTeam();
        }
    }, [location]);

    const handleThemeChange = (e) => {
        updateTheme({ [e.target.name]: e.target.value });
    };

    const handleConnectShopify = (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');
        try {
            const shop = shopifyStore.toLowerCase().trim();
            const shopRegex = /^[a-z0-9\-]+\.myshopify\.com$/;
            if (!shopRegex.test(shop)) throw new Error('Enter a valid Shopify realm (e.g., "your-store.myshopify.com")');
            const redirectUrl = `${window.location.origin}/auth/install?shop=${shop}`;
            window.location.href = redirectUrl;
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    const handleAddUser = async (e) => {
        e.preventDefault();
        setLoading(true);
        setUserError('');
        setUserSuccess('');

        try {
            const response = await axios.post('/add-user', newUser, { withCredentials: true });
            setUserSuccess(`Crew member ${newUser.name} forged successfully!`);
            setNewUser({ email: '', phoneNumber: '', name: '', role: 'member' });
            const teamResponse = await axios.get('/team-members', { withCredentials: true });
            setTeamMembers(teamResponse.data.teamMembers);
        } catch (err) {
            setUserError(err.response?.data?.message || 'Failed to forge new crew member');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="settings-page celestial-forge">
            <div className="forge-header">
                <h3>Settings</h3>
            </div>

            {/* User Identity Plaque */}
            <section className="forge-section identity-plaque">
                <h4>Identity</h4>
                <div className="plaque-content">
                    <span className="forge-name">{user?.name || 'Unknown Forger'}</span>
                    <div className="orbiting-star"></div>
                </div>
            </section>

            {/* Stellar Forge */}
            <section className="forge-section stellar-forge">
                <h4>Connected Stores</h4>
                <div className="forge-content">
                    {stores.length > 0 && (
                        <div className="orbiting-realms">
                            {stores.map(store => (
                                <div key={store.shopDomain} className="realm-badge">
                                    <span>{store.shopDomain}</span>
                                    <div className={`status-ring ${store.isActive ? 'active' : 'inactive'}`}>
                                        {store.isActive ? 'Active' : 'Inactive'}
                                    </div>
                                    <span className="forge-date">Conncted on: {new Date(store.connectedAt).toLocaleDateString()}</span>
                                </div>
                            ))}
                        </div>
                    )}
                    <form onSubmit={handleConnectShopify} className="forge-portal">
                        <label>Connect New Store</label>
                        <input
                            type="text"
                            value={shopifyStore}
                            onChange={(e) => setShopifyStore(e.target.value)}
                            placeholder="your-store.myshopify.com"
                            disabled={loading}
                        />
                        {error && <div className="forge-error">{error}</div>}
                        {success && <div className="forge-success">{success}</div>}
                        <button type="submit" className="forge-btn" disabled={loading}>
                            {loading ? 'Connecting...' : 'Connect'}
                        </button>
                    </form>
                </div>
            </section>

            {/* Crew Forge (Team Member Management) */}
            {user?.role === 'admin' && (
                <section className="forge-section crew-forge">
                    <h4>Team</h4>
                    {teamMembers.length > 0 && (
                        <div className="crew-list">
                            <h5>Current Team</h5>
                            <div className="crew-table">
                                <div className="crew-table-header">
                                    <span>Name</span>
                                    <span>Email</span>
                                    <span>Role</span>
                                </div>
                                {teamMembers.map(member => (
                                    <div key={member.email} className="crew-table-row">
                                        <span>{member.name}</span>
                                        <span>{member.email}</span>
                                        <span className="crew-role">{member.role}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    <form onSubmit={handleAddUser} className="forge-portal">
                        <label>Add New Member</label>
                        <input
                            type="email"
                            value={newUser.email}
                            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                            placeholder="Email"
                            disabled={loading}
                            required
                        />
                        <input
                            type="text"
                            value={newUser.phoneNumber}
                            onChange={(e) => setNewUser({ ...newUser, phoneNumber: e.target.value })}
                            placeholder="Phone Number"
                            disabled={loading}
                            required
                        />
                        <input
                            type="text"
                            value={newUser.name}
                            onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                            placeholder="Name"
                            disabled={loading}
                            required
                        />
                        <select
                            value={newUser.role}
                            onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
                            disabled={loading}
                        >
                            <option value="inventory_manager">Inventory Manager</option>
                            <option value="inventory_executive">Inventory Executive</option>
                            <option value="operation_manager">Operation Manager</option>
                            <option value="operation_executive">Operation Executive</option>
                            <option value="customer_support">Customer Support</option>
                            <option value="rto_executive">RTO Executive</option>
                            <option value="order_manager">Order Manager</option>
                            <option value="order_executive">Order Executive</option>
                            <option value="production_manager">Production Manager</option>
                            <option value="production_executive">Production Executive</option>
                            <option value="packaging_executive">Packaging Executive</option>
                            <option value="finance_manager">Finance Manager</option>
                        </select>
                        {userError && <div className="forge-error">{userError}</div>}
                        {userSuccess && <div className="forge-success">{userSuccess}</div>}
                        <button type="submit" className="forge-btn" disabled={loading}>
                            {loading ? 'Forging...' : 'Forge Crew Member'}
                        </button>
                    </form>
                </section>
            )}
        </div>
    );
};

export default SettingsPage;