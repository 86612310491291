/* react_frontend/src/pages/ProductionProgressPage.js */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ProductionProgressPage.css';

const ProductionProgressPage = () => {
    const [productionData, setProductionData] = useState({
        steps: [],
        dailyOutput: { today: 0, tomorrow: 0 },
        bottlenecks: []
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchProductionData();
    }, []);

    const fetchProductionData = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.get('/production/progress', { withCredentials: true });
            console.log('API Response:', response.data); // Debug log
            // Ensure response.data matches expected structure; fallback to defaults if not
            const data = {
                steps: Array.isArray(response.data?.steps) ? response.data.steps : [],
                dailyOutput: response.data?.dailyOutput || { today: 0, tomorrow: 0 },
                bottlenecks: Array.isArray(response.data?.bottlenecks) ? response.data.bottlenecks : []
            };
            setProductionData(data);
        } catch (err) {
            setError('Failed to stoke the forge: ' + (err.response?.data?.message || err.message));
            setProductionData({ steps: [], dailyOutput: { today: 0, tomorrow: 0 }, bottlenecks: [] }); // Reset on error
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="production-progress-page crimson-forge">
            <div className="forge-furnace">
                <h3>Forge of the Crimson Veil</h3>
            </div>

            {loading ? (
                <div className="forge-loading">
                    <div className="steam-spinner"></div>
                    <span>Stoking the Flames...</span>
                </div>
            ) : error ? (
                <div className="forge-error">{error}</div>
            ) : productionData.steps.length === 0 && productionData.bottlenecks.length === 0 ? (
                <div className="forge-empty">No production in progress.</div>
            ) : (
                <>
                    {/* Forging Stations */}
                    <section className="forge-section">
                        <h4>Forging Stations</h4>
                        <div className="station-grid">
                            {productionData.steps.map(step => (
                                <div key={step.id} className="station-panel">
                                    <div className="steam-gauge"></div>
                                    <span className="station-name">{step.name}</span>
                                    <div className="progress-bar" style={{ width: `${step.progress}%` }}></div>
                                    <span className="station-progress">{step.progress}% Complete</span>
                                    <span className="station-workers">Workers: {step.workers}</span>
                                    <span className="station-units">Units: {step.unitsInProgress}</span>
                                </div>
                            ))}
                        </div>
                    </section>

                    {/* Crimson Yield */}
                    <section className="forge-section">
                        <h4>Crimson Yield</h4>
                        <div className="yield-ticker">
                            <div className="yield-card">
                                <span>Today</span>
                                <div className="yield-value">{productionData.dailyOutput.today}</div>
                            </div>
                            <div className="yield-card">
                                <span>Tomorrow</span>
                                <div className="yield-value">{productionData.dailyOutput.tomorrow}</div>
                            </div>
                        </div>
                    </section>

                    {/* Steam Valves (Bottlenecks) */}
                    <section className="forge-section">
                        <h4>Steam Valves: Loggerheads</h4>
                        <div className="valve-grid">
                            {productionData.bottlenecks.map(bottleneck => (
                                <div key={bottleneck.stepId} className="valve-alert">
                                    <div className="valve-pulse"></div>
                                    <span className="valve-step">{bottleneck.stepName}</span>
                                    <span className="valve-issue">{bottleneck.issue}</span>
                                    <span className="valve-workers">Workers: {bottleneck.currentWorkers}</span>
                                    <span className="valve-suggestion">{bottleneck.suggestion}</span>
                                </div>
                            ))}
                        </div>
                    </section>
                </>
            )}
        </div>
    );
};

export default ProductionProgressPage;