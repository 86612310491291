// react_frontend/src/pages/Store/InventoryPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../InventoryPage.css';

const StoreInventoryPage = () => {
    const [finishedInventory, setFinishedInventory] = useState([]);
    const [packagingMaterials, setPackagingMaterials] = useState([]);
    const [loading, setLoading] = useState(false);
    const [syncLoading, setSyncLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [shopDomain, setShopDomain] = useState('');
    const [availableStores, setAvailableStores] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [newMaterial, setNewMaterial] = useState({ name: '', subcategory: '', unit: 'units', quantity: 0 });
    const [finishedPageInfo, setFinishedPageInfo] = useState({ hasNextPage: false, hasPreviousPage: false, endCursor: null, startCursor: null });
    const [packagingPageInfo, setPackagingPageInfo] = useState({ currentPage: 1, totalPages: 1, hasNextPage: false, hasPreviousPage: false });
    const [finishedCursor, setFinishedCursor] = useState({ after: null, before: null });
    const [packagingPage, setPackagingPage] = useState(1);
    const [adjustQuantity, setAdjustQuantity] = useState({ variantId: null, locationId: null, materialId: null, value: '', show: false });
    const [activeSection, setActiveSection] = useState('relics');
    const [activeSubcategories, setActiveSubcategories] = useState({});
    const [sortConfig, setSortConfig] = useState({ relics: { key: 'sku', direction: 'asc' }, essence: { key: 'name', direction: 'asc' } });
    const [hideCombos, setHideCombos] = useState(false);

    useEffect(() => {
        const fetchStores = async () => {
            try {
                const response = await axios.get('/shopify/stores', { withCredentials: true });
                setAvailableStores(response.data.stores);
                if (response.data.stores.length > 0) setShopDomain(response.data.stores[0].shopDomain);
            } catch (err) {
                console.error('Error fetching stores:', err);
            }
        };
        fetchStores();
    }, []);

    useEffect(() => {
        if (shopDomain) {
            fetchFinishedInventory();
            fetchPackagingMaterials();
        }
    }, [shopDomain, finishedCursor, packagingPage]);

    const fetchFinishedInventory = async () => {
        setLoading(true);
        setError('');
        try {
            const params = { shopDomain, limit: 10, after: finishedCursor.after, before: finishedCursor.before };
            const response = await axios.get('/shopify/inventory', { params, withCredentials: true });
            setFinishedInventory(response.data.products);
            setFinishedPageInfo(response.data.pageInfo);
        } catch (err) {
            setError('Failed to fetch finished goods: ' + (err.response?.data?.message || err.message));
        } finally {
            setLoading(false);
        }
    };

    const fetchPackagingMaterials = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.get('/store/inventory/packaging', { params: { page: packagingPage, limit: 100, shopDomain }, withCredentials: true });
            setPackagingMaterials(response.data.materials);
            setPackagingPageInfo(response.data.pageInfo);
        } catch (err) {
            setError('Failed to fetch packaging materials: ' + (err.response?.data?.message || err.message));
        } finally {
            setLoading(false);
        }
    };

    const handleStockChange = async (variantId, locationId, delta) => {
        try {
            const product = finishedInventory.find(item => item.variants.some(v => v.variantId === variantId));
            const variant = product.variants.find(v => v.variantId === variantId);
            const location = variant.locations.find(l => l.locationId === locationId);
            const newStock = Math.max(0, location.quantity + delta);
            await axios.post('/shopify/inventory/update', { shopDomain, variantId, locationId, stock: delta }, { withCredentials: true });
            const updatedInventory = finishedInventory.map(item => {
                if (item.id === product.id) {
                    return {
                        ...item,
                        variants: item.variants.map(v => {
                            if (v.variantId === variantId) {
                                return {
                                    ...v,
                                    locations: v.locations.map(l => l.locationId === locationId ? { ...l, quantity: newStock } : l),
                                    totalQuantity: v.locations.reduce((sum, l) => sum + (l.locationId === locationId ? newStock : l.quantity), 0),
                                };
                            }
                            return v;
                        }),
                    };
                }
                return item;
            });
            setFinishedInventory(updatedInventory);
        } catch (err) {
            setError('Failed to adjust stock: ' + (err.response?.data?.message || err.message));
            fetchFinishedInventory();
        }
    };

    const handleAdjustStock = async (variantId, locationId, delta) => {
        try {
            const product = finishedInventory.find(item => item.variants.some(v => v.variantId === variantId));
            const variant = product.variants.find(v => v.variantId === variantId);
            const location = variant.locations.find(l => l.locationId === locationId);
            const newStock = Math.max(0, location.quantity + delta);
            await axios.post('/shopify/inventory/update', { shopDomain, variantId, locationId, stock: delta }, { withCredentials: true });
            const updatedInventory = finishedInventory.map(item => {
                if (item.id === product.id) {
                    return {
                        ...item,
                        variants: item.variants.map(v => {
                            if (v.variantId === variantId) {
                                return {
                                    ...v,
                                    locations: v.locations.map(l => l.locationId === locationId ? { ...l, quantity: newStock } : l),
                                    totalQuantity: v.locations.reduce((sum, l) => sum + (l.locationId === locationId ? newStock : l.quantity), 0),
                                };
                            }
                            return v;
                        }),
                    };
                }
                return item;
            });
            setFinishedInventory(updatedInventory);
            setAdjustQuantity({ variantId: null, locationId: null, materialId: null, value: '', show: false });
        } catch (err) {
            setError('Failed to adjust stock: ' + (err.response?.data?.message || err.message));
            fetchFinishedInventory();
        }
    };

    const handleSyncComboInventory = async () => {
        setSyncLoading(true);
        setError('');
        setSuccess('');
        try {
            await axios.post('/shopify/inventory/sync-combo', { shopDomain }, { withCredentials: true });
            setSuccess('Combo inventory synced successfully!');
            fetchFinishedInventory();
        } catch (err) {
            setError('Failed to sync combo inventory: ' + (err.response?.data?.message || err.message));
        } finally {
            setSyncLoading(false);
        }
    };

    const handleAddMaterial = async () => {
        try {
            await axios.post('/store/inventory/packaging', { ...newMaterial, shopDomain }, { withCredentials: true });
            setShowAddModal(false);
            setNewMaterial({ name: '', subcategory: '', unit: 'units', quantity: 0 });
            fetchPackagingMaterials();
        } catch (err) {
            setError('Failed to add packaging material: ' + (err.response?.data?.message || err.message));
        }
    };

    const handleUpdatePackagingQuantity = async (materialId, delta) => {
        try {
            const material = packagingMaterials.find(m => m._id === materialId);
            const newQuantity = Math.max(0, material.quantity + delta);
            await axios.put(`/store/inventory/packaging/${materialId}`, { quantity: newQuantity }, { withCredentials: true });
            fetchPackagingMaterials();
        } catch (err) {
            setError('Failed to update packaging quantity: ' + (err.response?.data?.message || err.message));
        }
    };

    const handleAdjustPackagingQuantity = async (materialId, delta) => {
        try {
            const material = packagingMaterials.find(m => m._id === materialId);
            const newQuantity = Math.max(0, material.quantity + delta);
            await axios.put(`/store/inventory/packaging/${materialId}`, { quantity: newQuantity }, { withCredentials: true });
            fetchPackagingMaterials();
            setAdjustQuantity({ variantId: null, locationId: null, materialId: null, value: '', show: false });
        } catch (err) {
            setError('Failed to adjust packaging quantity: ' + (err.response?.data?.message || err.message));
        }
    };

    const handleDeletePackagingMaterial = async (materialId) => {
        if (window.confirm('Are you sure you want to delete this packaging material?')) {
            try {
                await axios.delete(`/store/inventory/packaging/${materialId}`, { withCredentials: true });
                fetchPackagingMaterials();
            } catch (err) {
                setError('Failed to delete packaging material: ' + (err.response?.data?.message || err.message));
            }
        }
    };

    const handleDuplicateMaterial = (materialId) => {
        const materialToDuplicate = packagingMaterials.find(m => m._id === materialId);
        if (materialToDuplicate) {
            setNewMaterial({
                name: materialToDuplicate.name,
                subcategory: materialToDuplicate.subcategory,
                unit: materialToDuplicate.unit,
                quantity: 0, // Start with 0 for new entry
            });
            setShowAddModal(true);
        }
    };

    const toggleAdjustInput = (variantId, locationId, materialId) => {
        setAdjustQuantity(prev => ({
            variantId,
            locationId,
            materialId,
            value: '',
            show: prev.variantId !== variantId || prev.locationId !== locationId || prev.materialId !== materialId || !prev.show,
        }));
    };

    const toggleSubcategory = (subcategory) => {
        setActiveSubcategories(prev => ({
            ...prev,
            [subcategory]: !prev[subcategory],
        }));
    };

    const handleFinishedNextPage = () => {
        if (finishedPageInfo.hasNextPage) setFinishedCursor({ after: finishedPageInfo.endCursor, before: null });
    };

    const handleFinishedPrevPage = () => {
        if (finishedPageInfo.hasPreviousPage) setFinishedCursor({ after: null, before: finishedPageInfo.startCursor });
    };

    const handlePackagingNextPage = () => {
        if (packagingPageInfo.hasNextPage) setPackagingPage(prev => prev + 1);
    };

    const handlePackagingPrevPage = () => {
        if (packagingPageInfo.hasPreviousPage) setPackagingPage(prev => prev - 1);
    };

    const handleSort = (section, key) => {
        const direction = sortConfig[section].key === key && sortConfig[section].direction === 'asc' ? 'desc' : 'asc';
        setSortConfig(prev => ({ ...prev, [section]: { key, direction } }));

        if (section === 'relics') {
            let allVariants = finishedInventory.flatMap(item => item.variants.map(variant => ({
                ...variant,
                productName: item.product,
            })));
            allVariants.sort((a, b) => {
                if (key === 'sku') return direction === 'asc' ? a.sku.localeCompare(b.sku) : b.sku.localeCompare(a.sku);
                if (key === 'quantity') return direction === 'asc' ? a.totalQuantity - b.totalQuantity : b.totalQuantity - a.totalQuantity;
                return 0;
            });
            setFinishedInventory([{ id: 'sorted', product: 'All Products', variants: allVariants }]);
        } else if (section === 'essence') {
            const sorted = [...packagingMaterials].sort((a, b) => {
                if (key === 'name') return direction === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
                if (key === 'quantity') return direction === 'asc' ? a.quantity - b.quantity : b.quantity - a.quantity;
                return 0;
            });
            setPackagingMaterials(sorted);
        }
    };

    const toggleHideCombos = () => {
        setHideCombos(prev => !prev);
    };

    const filteredFinishedInventory = hideCombos
        ? finishedInventory.map(item => ({
            ...item,
            variants: item.variants.filter(variant => !variant.sku.includes(',')),
        }))
        : finishedInventory;

    const groupedPackagingMaterials = packagingMaterials.reduce((acc, item) => {
        const subcategory = item.subcategory || 'Uncategorized';
        if (!acc[subcategory]) acc[subcategory] = [];
        acc[subcategory].push(item);
        return acc;
    }, {});

    return (
        <div className="inventory-page alchemist-vault">
            <div className="vault-header">
                <h3>Store Inventory</h3>
            </div>

            <div className="alchemist-compass">
                <label>Select Store</label>
                <select value={shopDomain} onChange={(e) => setShopDomain(e.target.value)}>
                    {availableStores.map(store => (
                        <option key={store.shopDomain} value={store.shopDomain}>{store.shopDomain}</option>
                    ))}
                </select>
            </div>

            {loading ? (
                <div className="vault-loading">
                    <div className="cauldron-spinner"></div>
                    <span>Loading Inventory...</span>
                </div>
            ) : error ? (
                <div className="vault-error">{error}</div>
            ) : (
                <div className="vault-sections">
                    {/* Finished Goods Section */}
                    <section className={`relic-vault ${activeSection === 'relics' ? 'active' : ''}`}>
                        <div className="section-header" onClick={() => setActiveSection(activeSection === 'relics' ? null : 'relics')}>
                            <h4>Finished Goods (Shopify)</h4>
                            <span className="toggle-icon">{activeSection === 'relics' ? '▲' : '▼'}</span>
                        </div>
                        {activeSection === 'relics' && (
                            <>
                                <div className="section-controls">
                                    <button
                                        className="sync-btn"
                                        onClick={handleSyncComboInventory}
                                        disabled={syncLoading || loading}
                                    >
                                        {syncLoading ? 'Syncing...' : 'Sync Combo Inventory'}
                                    </button>
                                    <div className="sort-controls">
                                        <label>Sort By:</label>
                                        <select
                                            value={`${sortConfig.relics.key}-${sortConfig.relics.direction}`}
                                            onChange={(e) => {
                                                const [key, direction] = e.target.value.split('-');
                                                handleSort('relics', key);
                                            }}
                                        >
                                            <option value="sku-asc">SKU (A-Z)</option>
                                            <option value="sku-desc">SKU (Z-A)</option>
                                            <option value="quantity-asc">Quantity (Low to High)</option>
                                            <option value="quantity-desc">Quantity (High to Low)</option>
                                        </select>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={hideCombos}
                                                onChange={toggleHideCombos}
                                            />
                                            Hide Combos
                                        </label>
                                    </div>
                                </div>
                                {success && <div className="vault-success">{success}</div>}
                                <div className="relic-showcase">
                                    {filteredFinishedInventory.flatMap(item =>
                                        item.variants.map(variant => (
                                            <div key={`${item.id}-${variant.sku}`} className="relic-card">
                                                <div className="relic-sku">{variant.sku}</div>
                                                <div className="relic-name">{item.product}</div>
                                                <div className="relic-locations">
                                                    {variant.locations.map(location => (
                                                        <div key={location.locationId} className="location-orb">
                                                            <span>{location.locationName}</span>
                                                            <div className="relic-quantity">
                                                                <button
                                                                    className="dial-btn"
                                                                    onClick={() => handleStockChange(variant.variantId, location.locationId, -1)}
                                                                >
                                                                    -
                                                                </button>
                                                                <span className="quantity-orb">{location.quantity}</span>
                                                                <button
                                                                    className="dial-btn"
                                                                    onClick={() => handleStockChange(variant.variantId, location.locationId, 1)}
                                                                >
                                                                    +
                                                                </button>
                                                                <button
                                                                    className="adjust-toggle"
                                                                    onClick={() => toggleAdjustInput(variant.variantId, location.locationId, null)}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16">
                                                                        <path fillRule="evenodd" d="M3.72 6.47a.75.75 0 0 1 1.06 0l3.47 3.47 3.47-3.47a.749.749 0 1 1 1.06 1.06l-4 4a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 0 1 0-1.06"></path>
                                                                    </svg>
                                                                </button>
                                                                {adjustQuantity.show && adjustQuantity.variantId === variant.variantId && adjustQuantity.locationId === location.locationId && (
                                                                    <div className="adjust-input">
                                                                        <input
                                                                            type="number"
                                                                            value={adjustQuantity.value}
                                                                            onChange={(e) => setAdjustQuantity({ ...adjustQuantity, value: e.target.value })}
                                                                            placeholder="-50"
                                                                        />
                                                                        <button
                                                                            className="adjust-btn"
                                                                            onClick={() => handleAdjustStock(variant.variantId, location.locationId, parseInt(adjustQuantity.value) || 0)}
                                                                        >
                                                                            Adjust
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="relic-total">Total: <span className="total-glow">{variant.totalQuantity}</span></div>
                                            </div>
                                        ))
                                    )}
                                </div>
                                <div className="vault-runes">
                                    <button
                                        className="rune-btn"
                                        onClick={handleFinishedPrevPage}
                                        disabled={!finishedPageInfo.hasPreviousPage || loading}
                                    >
                                        ←
                                    </button>
                                    <span className="rune-page">Page {finishedCursor.after ? 'Next' : finishedCursor.before ? 'Prev' : 1}</span>
                                    <button
                                        className="rune-btn"
                                        onClick={handleFinishedNextPage}
                                        disabled={!finishedPageInfo.hasNextPage || loading}
                                    >
                                        →
                                    </button>
                                </div>
                            </>
                        )}
                    </section>

                    {/* Packaging Materials Section */}
                    <section className={`essence-vault ${activeSection === 'essence' ? 'active' : ''}`}>
                        <div className="section-header" onClick={() => setActiveSection(activeSection === 'essence' ? null : 'essence')}>
                            <h4>Packaging Materials</h4>
                            <span className="toggle-icon">{activeSection === 'essence' ? '▲' : '▼'}</span>
                        </div>
                        {activeSection === 'essence' && (
                            <>
                                <div className="section-controls">
                                    <button className="forge-btn" onClick={() => setShowAddModal(true)}>Add New Material</button>
                                    <div className="sort-controls">
                                        <label>Sort By:</label>
                                        <select
                                            value={`${sortConfig.essence.key}-${sortConfig.essence.direction}`}
                                            onChange={(e) => {
                                                const [key, direction] = e.target.value.split('-');
                                                handleSort('essence', key);
                                            }}
                                        >
                                            <option value="name-asc">Name (A-Z)</option>
                                            <option value="name-desc">Name (Z-A)</option>
                                            <option value="quantity-asc">Quantity (Low to High)</option>
                                            <option value="quantity-desc">Quantity (High to Low)</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="essence-cauldrons">
                                    {Object.entries(groupedPackagingMaterials).map(([subcategory, items]) => (
                                        <div key={subcategory} className={`subcategory-section ${activeSubcategories[subcategory] ? 'active' : ''}`}>
                                            <div className="subcategory-header" onClick={() => toggleSubcategory(subcategory)}>
                                                <h5>{subcategory} ({items.length})</h5>
                                                <span className="toggle-icon">{activeSubcategories[subcategory] ? '▲' : '▼'}</span>
                                            </div>
                                            {activeSubcategories[subcategory] && (
                                                <div className="subcategory-content">
                                                    {items.map(item => (
                                                        <div key={item._id} className="cauldron">
                                                            <div className="cauldron-name">{item.name}</div>
                                                            <div className="cauldron-unit">{item.unit}</div>
                                                            <div className="cauldron-quantity">
                                                                <button className="dial-btn" onClick={() => handleUpdatePackagingQuantity(item._id, -1)}>-</button>
                                                                <span className="quantity-orb">{item.quantity}</span>
                                                                <button className="dial-btn" onClick={() => handleUpdatePackagingQuantity(item._id, 1)}>+</button>
                                                                <button
                                                                    className="adjust-toggle"
                                                                    onClick={() => toggleAdjustInput(null, null, item._id)}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16">
                                                                        <path fillRule="evenodd" d="M3.72 6.47a.75.75 0 0 1 1.06 0l3.47 3.47 3.47-3.47a.749.749 0 1 1 1.06 1.06l-4 4a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 0 1 0-1.06"></path>
                                                                    </svg>
                                                                </button>
                                                                {adjustQuantity.show && adjustQuantity.materialId === item._id && (
                                                                    <div className="adjust-input">
                                                                        <input
                                                                            type="number"
                                                                            value={adjustQuantity.value}
                                                                            onChange={(e) => setAdjustQuantity({ ...adjustQuantity, value: e.target.value })}
                                                                            placeholder="-50"
                                                                        />
                                                                        <button
                                                                            className="adjust-btn"
                                                                            onClick={() => handleAdjustPackagingQuantity(item._id, parseInt(adjustQuantity.value) || 0)}
                                                                        >
                                                                            Adjust
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <button className="crucible-btn" onClick={() => handleDeletePackagingMaterial(item._id)}>Delete</button>
                                                            <button className="duplicate-btn" onClick={() => handleDuplicateMaterial(item._id)}>Duplicate</button>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="vault-runes">
                                    <button
                                        className="rune-btn"
                                        onClick={handlePackagingPrevPage}
                                        disabled={!packagingPageInfo.hasPreviousPage || loading}
                                    >
                                        ←
                                    </button>
                                    <span className="rune-page">Page {packagingPage} of {packagingPageInfo.totalPages}</span>
                                    <button
                                        className="rune-btn"
                                        onClick={handlePackagingNextPage}
                                        disabled={!packagingPageInfo.hasNextPage || loading}
                                    >
                                        →
                                    </button>
                                </div>
                            </>
                        )}
                    </section>
                </div>
            )}

            {/* Add Packaging Material Modal */}
            <div className={`grimoire-modal ${showAddModal ? 'active' : ''}`}>
                <div className="grimoire-overlay" onClick={() => setShowAddModal(false)}></div>
                <div className="grimoire-content">
                    <button className="grimoire-close" onClick={() => setShowAddModal(false)}>✕</button>
                    <h3>Add New Packaging Material</h3>
                    <div className="grimoire-page">
                        <label>Duplicate Existing Material</label>
                        <select
                            onChange={(e) => {
                                if (e.target.value) handleDuplicateMaterial(e.target.value);
                                e.target.value = ''; // Reset dropdown
                            }}
                        >
                            <option value="">Select to duplicate...</option>
                            {packagingMaterials.map(material => (
                                <option key={material._id} value={material._id}>
                                    {material.name} ({material.subcategory || 'Uncategorized'})
                                </option>
                            ))}
                        </select>
                        <label>Name</label>
                        <input
                            type="text"
                            value={newMaterial.name}
                            onChange={(e) => setNewMaterial({ ...newMaterial, name: e.target.value })}
                            placeholder="e.g., Cardboard Box"
                        />
                        <label>Subcategory</label>
                        <input
                            type="text"
                            value={newMaterial.subcategory}
                            onChange={(e) => setNewMaterial({ ...newMaterial, subcategory: e.target.value })}
                            placeholder="e.g., Boxes"
                        />
                        <label>Unit</label>
                        <select value={newMaterial.unit} onChange={(e) => setNewMaterial({ ...newMaterial, unit: e.target.value })}>
                            <option value="units">Units</option>
                            <option value="meters">Meters</option>
                            <option value="kg">Kilograms</option>
                        </select>
                        <label>Quantity</label>
                        <input
                            type="number"
                            value={newMaterial.quantity}
                            onChange={(e) => setNewMaterial({ ...newMaterial, quantity: parseInt(e.target.value) || 0 })}
                            min="0"
                        />
                        {error && <div className="vault-error">{error}</div>}
                        <button className="inscribe-btn" onClick={handleAddMaterial}>Add</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StoreInventoryPage;