// react_frontend/src/pages/ProductionDashboard.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import './ProductionDashboard.css';

const ProductionDashboard = () => {
    const { user } = useAuth();
    const [stats, setStats] = useState({
        materialUsage: {},
        productionBySizeColor: {},
        stepDurations: [],
        shipped: 0,
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [shopDomain, setShopDomain] = useState('');
    const [stores, setStores] = useState([]);
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [showAddStep, setShowAddStep] = useState(false);
    const [newStep, setNewStep] = useState({ name: '', stepOrder: '' });
    const [stepError, setStepError] = useState('');
    const [stepSuccess, setStepSuccess] = useState('');

    console.log('ProductionDashboard rendered - User object:', user);
    console.log('User role:', user?.role);
    console.log('Is admin check:', user?.role === 'admin');

    useEffect(() => {
        fetchStores();
    }, []);

    const fetchStores = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/shopify/stores', { withCredentials: true });
            setStores(response.data.stores);
            if (response.data.stores.length > 0) {
                setShopDomain(response.data.stores[0].shopDomain);
                fetchStats(response.data.stores[0].shopDomain);
            }
        } catch (err) {
            console.error('Error fetching stores:', err);
            setError('Failed to fetch stores: ' + (err.response?.data?.message || err.message));
        } finally {
            setLoading(false);
        }
    };

    const fetchStats = async (selectedShopDomain = shopDomain) => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.get(`/production/dashboard?shopDomain=${selectedShopDomain}&date=${date}`, { withCredentials: true });
            setStats(response.data);
        } catch (err) {
            setError('Failed to fetch production stats: ' + (err.response?.data?.message || err.message));
        } finally {
            setLoading(false);
        }
    };

    const handleShopChange = (e) => {
        const newShopDomain = e.target.value;
        setShopDomain(newShopDomain);
        fetchStats(newShopDomain);
    };

    const handleAddStep = async (e) => {
        e.preventDefault();
        setStepError('');
        setStepSuccess('');
        try {
            const response = await axios.post('/production/steps', { ...newStep, shopDomain }, { withCredentials: true });
            setStepSuccess('Step forged successfully!');
            setNewStep({ name: '', stepOrder: '' });
            setShowAddStep(false); // Close form on success
            fetchStats();
        } catch (err) {
            setStepError('Failed to forge step: ' + (err.response?.data?.message || err.message));
        }
    };

    return (
        <div className="production-dashboard cosmic-hub">
            <div className="hub-header">
                <h3>Production Dashboard</h3>
                <div className="header-controls">
                    {stores.length > 1 && (
                        <select value={shopDomain} onChange={handleShopChange} className="shop-selector">
                            {stores.map(store => (
                                <option key={store.shopDomain} value={store.shopDomain}>
                                    {store.shopDomain}
                                </option>
                            ))}
                        </select>
                    )}
                    <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        onBlur={() => fetchStats()}
                        className="date-picker"
                    />
                    {user?.role === 'admin' && (
                        <button
                            onClick={() => setShowAddStep(!showAddStep)}
                            className={`add-step-toggle ${showAddStep ? 'active' : ''}`}
                        >
                            {showAddStep ? 'Close Forge' : 'Forge New Step'}
                        </button>
                    )}
                </div>
            </div>

            {loading ? (
                <div className="star-loading">
                    <div className="orbit-diviner"></div>
                    <span>Consulting the Cosmic Forge...</span>
                </div>
            ) : error ? (
                <div className="diviner-error">{error}</div>
            ) : (
                <div className="dashboard-content">
                    {user?.role === 'admin' && showAddStep && (
                        <section className="dashboard-section add-step-section">
                            <h4>Forge New Production Step</h4>
                            <form onSubmit={handleAddStep} className="add-step-form">
                                <div className="form-field">
                                    <label>Step Name</label>
                                    <input
                                        type="text"
                                        value={newStep.name}
                                        onChange={(e) => setNewStep({ ...newStep, name: e.target.value })}
                                        placeholder="e.g., Cutting"
                                        required
                                        className="step-input"
                                    />
                                </div>
                                <div className="form-field">
                                    <label>Step Order</label>
                                    <input
                                        type="number"
                                        value={newStep.stepOrder}
                                        onChange={(e) => setNewStep({ ...newStep, stepOrder: e.target.value })}
                                        placeholder="e.g., 1"
                                        min="1"
                                        required
                                        className="step-input"
                                    />
                                </div>
                                <button type="submit" className="forge-btn">Forge Step</button>
                                {stepError && <div className="forge-error">{stepError}</div>}
                                {stepSuccess && <div className="forge-success">{stepSuccess}</div>}
                            </form>
                        </section>
                    )}

                    <section className="dashboard-section">
                        <h4>Material Usage Today</h4>
                        <div className="material-grid">
                            {Object.entries(stats.materialUsage).map(([material, qty]) => (
                                <div key={material} className="material-card">
                                    <span className="card-title">{material}</span>
                                    <span className="card-value">{qty} cm</span>
                                </div>
                            ))}
                        </div>
                    </section>

                    <section className="dashboard-section">
                        <h4>Production Today</h4>
                        <div className="production-grid">
                            {Object.entries(stats.productionBySizeColor).map(([key, qty]) => (
                                <div key={key} className="production-card">
                                    <span className="card-title">{key}</span>
                                    <span className="card-value">{qty} units</span>
                                </div>
                            ))}
                        </div>
                    </section>

                    <section className="dashboard-section">
                        <h4>Step Durations</h4>
                        <div className="duration-grid">
                            {stats.stepDurations.map(step => (
                                <div key={step._id} className="duration-card">
                                    <span className="card-title">{step._id}</span>
                                    <span className="card-value">Avg: {(step.avgDuration / 60000).toFixed(2)} min</span>
                                    <span className="card-subvalue">Count: {step.count}</span>
                                </div>
                            ))}
                        </div>
                    </section>

                    <section className="dashboard-section">
                        <h4>Shipped Today</h4>
                        <div className="shipped-card">
                            <span className="card-value">{stats.shipped} orders</span>
                        </div>
                    </section>
                </div>
            )}
        </div>
    );
};

export default ProductionDashboard;