/* react_frontend/src/components/ShipmentTable.js */

import React from 'react';
import { daysDifference, formatDate } from '../utils/dateUtils';
import { getLastComment } from '../utils/commentUtils';

const ShipmentTable = ({ shipments, onViewDetails }) => (
  <div className="flight-table glass-table">
    <div className="table-header">
      <div>Order & Tracking</div>
      <div>Status</div>
    </div>
    {shipments.map((shipment) => (
      <div key={shipment.tracking_id} className="table-row">
        <div className="order-cell">
          <div className="order-number">{shipment.shipment.order_details.order_number}</div>
          <div className="payment-type">{shipment.shipment.order_details.order_type}</div>
          <div className="tracking">{shipment.shipment.awb_no}</div>
          <a
            href={`https://healthfab.ithinklogistics.co.in/postship/tracking/${shipment.shipment.awb_no}`}
            target="_blank"
            rel="noreferrer"
            className="tracking-link"
          >
            {shipment.shipment.logistic}
          </a>
          <div className="days">{daysDifference(shipment.shipment)} Days</div>
          <button className="view-blip" onClick={() => onViewDetails(shipment.tracking_id)}>
            View
          </button>
        </div>
        <div className="status-cell">
          <div className={`status-tag ${shipment.shipment.current_status.toLowerCase()}`}>
            {shipment.shipment.current_status}
          </div>
          <div className="remark">{shipment.shipment.last_scan_details.remark}</div>
          <div className="comment">{getLastComment(shipment.shipment)}</div>
          <div>First Exception: {formatDate(shipment.createdAt)}</div>
        </div>
      </div>
    ))}
  </div>
);

export default ShipmentTable;