// react_frontend/src/pages/Production/InventoryPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../InventoryPage.css';

const ProductionInventoryPage = () => {
    const [rawMaterials, setRawMaterials] = useState([]);
    const [finishedGoods, setFinishedGoods] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [shopDomain, setShopDomain] = useState('');
    const [availableStores, setAvailableStores] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [newMaterial, setNewMaterial] = useState({ name: '', category: 'raw_material', subcategory: '', unit: 'meters', properties: { color: '', type: '' }, quantity: 0 });
    const [rawPageInfo, setRawPageInfo] = useState({ currentPage: 1, totalPages: 1, hasNextPage: false, hasPreviousPage: false });
    const [finishedPageInfo, setFinishedPageInfo] = useState({ currentPage: 1, totalPages: 1, hasNextPage: false, hasPreviousPage: false });
    const [rawPage, setRawPage] = useState(1);
    const [finishedPage, setFinishedPage] = useState(1);
    const [adjustQuantity, setAdjustQuantity] = useState({ materialId: null, value: '', show: false });
    const [activeSection, setActiveSection] = useState('essence');
    const [activeSubcategories, setActiveSubcategories] = useState({});
    const [sortConfig, setSortConfig] = useState({ essence: { key: 'name', direction: 'asc' }, relics: { key: 'batchId', direction: 'asc' } });

    useEffect(() => {
        const fetchStores = async () => {
            try {
                const response = await axios.get('/shopify/stores', { withCredentials: true });
                setAvailableStores(response.data.stores);
                if (response.data.stores.length > 0) setShopDomain(response.data.stores[0].shopDomain);
            } catch (err) {
                console.error('Error fetching stores:', err);
            }
        };
        fetchStores();
    }, []);

    useEffect(() => {
        if (shopDomain) {
            fetchRawMaterials();
            fetchFinishedGoods();
        }
    }, [shopDomain, rawPage, finishedPage]);

    const fetchRawMaterials = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.get('/production/inventory/raw', { params: { page: rawPage, limit: 100, shopDomain }, withCredentials: true });
            setRawMaterials(response.data.materials);
            setRawPageInfo(response.data.pageInfo);
        } catch (err) {
            setError('Failed to fetch raw materials: ' + (err.response?.data?.message || err.message));
        } finally {
            setLoading(false);
        }
    };

    const fetchFinishedGoods = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.get('/production/inventory/finished', { params: { page: finishedPage, limit: 100, shopDomain }, withCredentials: true });
            setFinishedGoods(response.data.batches);
            setFinishedPageInfo(response.data.pageInfo);
        } catch (err) {
            setError('Failed to fetch finished goods: ' + (err.response?.data?.message || err.message));
        } finally {
            setLoading(false);
        }
    };

    const handleAddMaterial = async () => {
        try {
            await axios.post('/production/inventory/raw', { ...newMaterial, shopDomain }, { withCredentials: true });
            setShowAddModal(false);
            setNewMaterial({ name: '', category: 'raw_material', subcategory: '', unit: 'meters', properties: { color: '', type: '' }, quantity: 0 });
            fetchRawMaterials();
        } catch (err) {
            setError('Failed to add material: ' + (err.response?.data?.message || err.message));
        }
    };

    const handleUpdateQuantity = async (materialId, delta) => {
        try {
            const material = rawMaterials.find(m => m._id === materialId);
            const newQuantity = Math.max(0, material.quantity + delta);
            await axios.put(`/production/inventory/raw/${materialId}`, { quantity: newQuantity }, { withCredentials: true });
            fetchRawMaterials();
        } catch (err) {
            setError('Failed to update quantity: ' + (err.response?.data?.message || err.message));
        }
    };

    const handleAdjustQuantity = async (materialId, delta) => {
        try {
            const material = rawMaterials.find(m => m._id === materialId);
            const newQuantity = Math.max(0, material.quantity + delta);
            await axios.put(`/production/inventory/raw/${materialId}`, { quantity: newQuantity }, { withCredentials: true });
            fetchRawMaterials();
            setAdjustQuantity({ materialId: null, value: '', show: false });
        } catch (err) {
            setError('Failed to adjust quantity: ' + (err.response?.data?.message || err.message));
        }
    };

    const handleDeleteMaterial = async (materialId) => {
        if (window.confirm('Are you sure you want to delete this material?')) {
            try {
                await axios.delete(`/production/inventory/raw/${materialId}`, { withCredentials: true });
                fetchRawMaterials();
            } catch (err) {
                setError('Failed to delete material: ' + (err.response?.data?.message || err.message));
            }
        }
    };

    const handleTransferToShopify = async (batchId) => {
        try {
            await axios.post('/production/inventory/transfer', { batchId, shopDomain }, { withCredentials: true });
            fetchFinishedGoods();
        } catch (err) {
            setError('Failed to transfer to Shopify: ' + (err.response?.data?.message || err.message));
        }
    };

    const handleDuplicateMaterial = (materialId) => {
        const materialToDuplicate = rawMaterials.find(m => m._id === materialId);
        if (materialToDuplicate) {
            setNewMaterial({
                name: materialToDuplicate.name,
                category: materialToDuplicate.category,
                subcategory: materialToDuplicate.subcategory,
                unit: materialToDuplicate.unit,
                properties: { ...materialToDuplicate.properties },
                quantity: 0, // Start with 0 for new entry
            });
            setShowAddModal(true);
        }
    };

    const toggleAdjustInput = (materialId) => {
        setAdjustQuantity(prev => ({
            materialId,
            value: '',
            show: prev.materialId !== materialId || !prev.show,
        }));
    };

    const toggleSubcategory = (subcategory) => {
        setActiveSubcategories(prev => ({
            ...prev,
            [subcategory]: !prev[subcategory],
        }));
    };

    const handleRawNextPage = () => {
        if (rawPageInfo.hasNextPage) setRawPage(prev => prev + 1);
    };

    const handleRawPrevPage = () => {
        if (rawPageInfo.hasPreviousPage) setRawPage(prev => prev - 1);
    };

    const handleFinishedNextPage = () => {
        if (finishedPageInfo.hasNextPage) setFinishedPage(prev => prev + 1);
    };

    const handleFinishedPrevPage = () => {
        if (finishedPageInfo.hasPreviousPage) setFinishedPage(prev => prev - 1);
    };

    const handleSort = (section, key) => {
        const direction = sortConfig[section].key === key && sortConfig[section].direction === 'asc' ? 'desc' : 'asc';
        setSortConfig(prev => ({ ...prev, [section]: { key, direction } }));

        if (section === 'essence') {
            const sorted = [...rawMaterials].sort((a, b) => {
                if (key === 'name') return direction === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
                if (key === 'quantity') return direction === 'asc' ? a.quantity - b.quantity : b.quantity - a.quantity;
                return 0;
            });
            setRawMaterials(sorted);
        } else if (section === 'relics') {
            const sorted = [...finishedGoods].sort((a, b) => {
                if (key === 'batchId') return direction === 'asc' ? a.batchId.localeCompare(b.batchId) : b.batchId.localeCompare(a.batchId);
                return 0;
            });
            setFinishedGoods(sorted);
        }
    };

    const groupedRawMaterials = rawMaterials.reduce((acc, item) => {
        const subcategory = item.subcategory || 'Uncategorized';
        if (!acc[subcategory]) acc[subcategory] = [];
        acc[subcategory].push(item);
        return acc;
    }, {});

    return (
        <div className="inventory-page alchemist-vault">
            <div className="vault-header">
                <h3>Production Inventory</h3>
            </div>

            <div className="alchemist-compass">
                <label>Select Store</label>
                <select value={shopDomain} onChange={(e) => setShopDomain(e.target.value)}>
                    {availableStores.map(store => (
                        <option key={store.shopDomain} value={store.shopDomain}>{store.shopDomain}</option>
                    ))}
                </select>
            </div>

            {loading ? (
                <div className="vault-loading">
                    <div className="cauldron-spinner"></div>
                    <span>Loading Inventory...</span>
                </div>
            ) : error ? (
                <div className="vault-error">{error}</div>
            ) : (
                <div className="vault-sections">
                    {/* Raw Materials Section */}
                    <section className={`essence-vault ${activeSection === 'essence' ? 'active' : ''}`}>
                        <div className="section-header" onClick={() => setActiveSection(activeSection === 'essence' ? null : 'essence')}>
                            <h4>Raw Materials & Equipment</h4>
                            <span className="toggle-icon">{activeSection === 'essence' ? '▲' : '▼'}</span>
                        </div>
                        {activeSection === 'essence' && (
                            <>
                                <div className="section-controls">
                                    <button className="forge-btn" onClick={() => setShowAddModal(true)}>Add New Material</button>
                                    <div className="sort-controls">
                                        <label>Sort By:</label>
                                        <select
                                            value={`${sortConfig.essence.key}-${sortConfig.essence.direction}`}
                                            onChange={(e) => {
                                                const [key, direction] = e.target.value.split('-');
                                                handleSort('essence', key);
                                            }}
                                        >
                                            <option value="name-asc">Name (A-Z)</option>
                                            <option value="name-desc">Name (Z-A)</option>
                                            <option value="quantity-asc">Quantity (Low to High)</option>
                                            <option value="quantity-desc">Quantity (High to Low)</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="essence-cauldrons">
                                    {Object.entries(groupedRawMaterials).map(([subcategory, items]) => (
                                        <div key={subcategory} className={`subcategory-section ${activeSubcategories[subcategory] ? 'active' : ''}`}>
                                            <div className="subcategory-header" onClick={() => toggleSubcategory(subcategory)}>
                                                <h5>{subcategory} ({items.length})</h5>
                                                <span className="toggle-icon">{activeSubcategories[subcategory] ? '▲' : '▼'}</span>
                                            </div>
                                            {activeSubcategories[subcategory] && (
                                                <div className="subcategory-content">
                                                    {items.map(item => (
                                                        <div key={item._id} className="cauldron">
                                                            <div className="cauldron-name">{item.name} ({item.category})</div>
                                                            <div className="cauldron-unit">{item.unit}</div>
                                                            <div className="cauldron-properties">
                                                                {item.properties.color || item.properties.type ? `${item.properties.color || ''} ${item.properties.type || ''}`.trim() : 'N/A'}
                                                            </div>
                                                            <div className="cauldron-quantity">
                                                                <button className="dial-btn" onClick={() => handleUpdateQuantity(item._id, -1)}>-</button>
                                                                <span className="quantity-orb">{item.quantity}</span>
                                                                <button className="dial-btn" onClick={() => handleUpdateQuantity(item._id, 1)}>+</button>
                                                                <button
                                                                    className="adjust-toggle"
                                                                    onClick={() => toggleAdjustInput(item._id)}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16">
                                                                        <path fillRule="evenodd" d="M3.72 6.47a.75.75 0 0 1 1.06 0l3.47 3.47 3.47-3.47a.749.749 0 1 1 1.06 1.06l-4 4a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 0 1 0-1.06"></path>
                                                                    </svg>
                                                                </button>
                                                                {adjustQuantity.show && adjustQuantity.materialId === item._id && (
                                                                    <div className="adjust-input">
                                                                        <input
                                                                            type="number"
                                                                            value={adjustQuantity.value}
                                                                            onChange={(e) => setAdjustQuantity({ ...adjustQuantity, value: e.target.value })}
                                                                            placeholder="-50"
                                                                        />
                                                                        <button
                                                                            className="adjust-btn"
                                                                            onClick={() => handleAdjustQuantity(item._id, parseInt(adjustQuantity.value) || 0)}
                                                                        >
                                                                            Adjust
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <button className="crucible-btn" onClick={() => handleDeleteMaterial(item._id)}>Delete</button>
                                                            <button className="duplicate-btn" onClick={() => handleDuplicateMaterial(item._id)}>Duplicate</button>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="vault-runes">
                                    <button
                                        className="rune-btn"
                                        onClick={handleRawPrevPage}
                                        disabled={!rawPageInfo.hasPreviousPage || loading}
                                    >
                                        ←
                                    </button>
                                    <span className="rune-page">Page {rawPage} of {rawPageInfo.totalPages}</span>
                                    <button
                                        className="rune-btn"
                                        onClick={handleRawNextPage}
                                        disabled={!rawPageInfo.hasNextPage || loading}
                                    >
                                        →
                                    </button>
                                </div>
                            </>
                        )}
                    </section>

                    {/* Finished Goods Section */}
                    <section className={`relic-vault ${activeSection === 'relics' ? 'active' : ''}`}>
                        <div className="section-header" onClick={() => setActiveSection(activeSection === 'relics' ? null : 'relics')}>
                            <h4>Finished Goods</h4>
                            <span className="toggle-icon">{activeSection === 'relics' ? '▲' : '▼'}</span>
                        </div>
                        {activeSection === 'relics' && (
                            <>
                                <div className="section-controls">
                                    <div className="sort-controls">
                                        <label>Sort By:</label>
                                        <select
                                            value={`${sortConfig.relics.key}-${sortConfig.relics.direction}`}
                                            onChange={(e) => {
                                                const [key, direction] = e.target.value.split('-');
                                                handleSort('relics', key);
                                            }}
                                        >
                                            <option value="batchId-asc">Batch ID (A-Z)</option>
                                            <option value="batchId-desc">Batch ID (Z-A)</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="relic-showcase">
                                    {finishedGoods.map(batch => (
                                        <div key={batch.batchId} className="relic-card">
                                            <div className="relic-sku">Batch: {batch.batchId}</div>
                                            <div className="relic-locations">
                                                {batch.items.map(item => (
                                                    <div key={item.qrCode} className="location-orb">
                                                        <span>{item.sku} - {item.quantity} units</span>
                                                        <div className="relic-quantity">
                                                            {item.transferredToShopify ? (
                                                                <span className="quantity-orb">Transferred</span>
                                                            ) : (
                                                                <button
                                                                    className="adjust-btn"
                                                                    onClick={() => handleTransferToShopify(batch.batchId)}
                                                                >
                                                                    Transfer to Shopify
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="vault-runes">
                                    <button
                                        className="rune-btn"
                                        onClick={handleFinishedPrevPage}
                                        disabled={!finishedPageInfo.hasPreviousPage || loading}
                                    >
                                        ←
                                    </button>
                                    <span className="rune-page">Page {finishedPage} of {finishedPageInfo.totalPages}</span>
                                    <button
                                        className="rune-btn"
                                        onClick={handleFinishedNextPage}
                                        disabled={!finishedPageInfo.hasNextPage || loading}
                                    >
                                        →
                                    </button>
                                </div>
                            </>
                        )}
                    </section>
                </div>
            )}

            {/* Add Material Modal */}
            <div className={`grimoire-modal ${showAddModal ? 'active' : ''}`}>
                <div className="grimoire-overlay" onClick={() => setShowAddModal(false)}></div>
                <div className="grimoire-content">
                    <button className="grimoire-close" onClick={() => setShowAddModal(false)}>✕</button>
                    <h3>Add New Material</h3>
                    <div className="grimoire-page">
                        <label>Duplicate Existing Material</label>
                        <select
                            onChange={(e) => {
                                if (e.target.value) handleDuplicateMaterial(e.target.value);
                                e.target.value = ''; // Reset dropdown
                            }}
                        >
                            <option value="">Select to duplicate...</option>
                            {rawMaterials.map(material => (
                                <option key={material._id} value={material._id}>
                                    {material.name} ({material.category}, {material.subcategory || 'Uncategorized'})
                                </option>
                            ))}
                        </select>
                        <label>Name</label>
                        <input
                            type="text"
                            value={newMaterial.name}
                            onChange={(e) => setNewMaterial({ ...newMaterial, name: e.target.value })}
                            placeholder="e.g., Cotton"
                        />
                        <label>Category</label>
                        <select value={newMaterial.category} onChange={(e) => setNewMaterial({ ...newMaterial, category: e.target.value })}>
                            <option value="raw_material">Raw Material</option>
                            <option value="equipment">Equipment</option>
                            <option value="packaging">Packaging</option>
                            <option value="part">Part</option>
                        </select>
                        <label>Subcategory</label>
                        <input
                            type="text"
                            value={newMaterial.subcategory}
                            onChange={(e) => setNewMaterial({ ...newMaterial, subcategory: e.target.value })}
                            placeholder="e.g., Fabric"
                        />
                        <label>Unit</label>
                        <select value={newMaterial.unit} onChange={(e) => setNewMaterial({ ...newMaterial, unit: e.target.value })}>
                            <option value="meters">Meters</option>
                            <option value="kg">Kilograms</option>
                            <option value="units">Units</option>
                            <option value="liters">Liters</option>
                            <option value="cone">Cone</option>
                        </select>
                        <label>Color</label>
                        <input
                            type="text"
                            value={newMaterial.properties.color}
                            onChange={(e) => setNewMaterial({ ...newMaterial, properties: { ...newMaterial.properties, color: e.target.value } })}
                            placeholder="e.g., Red"
                        />
                        <label>Type</label>
                        <input
                            type="text"
                            value={newMaterial.properties.type}
                            onChange={(e) => setNewMaterial({ ...newMaterial, properties: { ...newMaterial.properties, type: e.target.value } })}
                            placeholder="e.g., Cotton"
                        />
                        <label>Quantity</label>
                        <input
                            type="number"
                            value={newMaterial.quantity}
                            onChange={(e) => setNewMaterial({ ...newMaterial, quantity: parseInt(e.target.value) || 0 })}
                            min="0"
                        />
                        {error && <div className="vault-error">{error}</div>}
                        <button className="inscribe-btn" onClick={handleAddMaterial}>Add</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductionInventoryPage;