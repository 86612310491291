// react_frontend/src/pages/Production/MaterialAssignmentPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../InventoryPage.css';

const MaterialAssignmentPage = () => {
    const [rawMaterials, setRawMaterials] = useState([]);
    const [assignments, setAssignments] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [shopDomain, setShopDomain] = useState('');
    const [availableStores, setAvailableStores] = useState([]);
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [assignmentData, setAssignmentData] = useState({
        materialId: '',
        assignedToEmail: '',
        quantity: 0,
        purpose: '',
        notes: '',
        batchReference: '',
    });
    const [pageInfo, setPageInfo] = useState({ currentPage: 1, totalPages: 1, hasNextPage: false, hasPreviousPage: false });
    const [page, setPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: 'dateAssigned', direction: 'desc' });
    const [filters, setFilters] = useState({
        startDate: '',
        endDate: '',
        materialName: '',
        assignedTo: '',
        status: '',
        purpose: '',
        batchReference: '',
    });

    useEffect(() => {
        const fetchStores = async () => {
            try {
                const response = await axios.get('/shopify/stores', { withCredentials: true });
                console.log('Stores response:', response.data);
                setAvailableStores(response.data.stores || []);
                if (response.data.stores?.length > 0) setShopDomain(response.data.stores[0].shopDomain);
            } catch (err) {
                console.error('Error fetching stores:', err);
                setError('Failed to fetch stores: ' + (err.response?.data?.message || err.message));
                setAvailableStores([]);
            }
        };
        fetchStores();
    }, []);

    useEffect(() => {
        if (shopDomain) {
            fetchRawMaterials();
            fetchAssignments();
            fetchUsers();
        }
    }, [shopDomain, page, filters]); // Add filters to dependency array

    const fetchRawMaterials = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.get('/production/inventory/raw', {
                params: { page: 1, limit: 100, shopDomain },
                withCredentials: true,
            });
            console.log('Raw materials response:', response.data);
            setRawMaterials(response.data.materials || []);
        } catch (err) {
            console.error('Error fetching raw materials:', err);
            setError('Failed to fetch raw materials: ' + (err.response?.data?.message || err.message));
            setRawMaterials([]);
        } finally {
            setLoading(false);
        }
    };

    const fetchAssignments = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.get('/production/inventory/assignments', {
                params: {
                    page,
                    limit: 10,
                    shopDomain,
                    ...filters, // Spread filter parameters
                },
                withCredentials: true,
            });
            console.log('Assignments response:', response.data);
            setAssignments(response.data.assignments || []);
            setPageInfo(response.data.pageInfo || { currentPage: 1, totalPages: 1, hasNextPage: false, hasPreviousPage: false });
        } catch (err) {
            console.error('Error fetching assignments:', err);
            setError('Failed to fetch assignments: ' + (err.response?.data?.message || err.message));
            setAssignments([]);
        } finally {
            setLoading(false);
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await axios.get('/production/users', { withCredentials: true });
            console.log('Users response:', response);
            console.log('Users data:', response.data.users);
            setUsers(response.data.users || []);
        } catch (err) {
            console.error('Error fetching users:', err);
            setError('Failed to fetch users: ' + (err.response?.data?.message || err.message));
            setUsers([]);
        }
    };

    const handleAssignMaterial = async () => {
        setLoading(true);
        try {
            await axios.post('/production/inventory/assign', { ...assignmentData, shopDomain }, { withCredentials: true });
            setShowAssignModal(false);
            setAssignmentData({ materialId: '', assignedToEmail: '', quantity: 0, purpose: '', notes: '', batchReference: '' });
            fetchRawMaterials();
            fetchAssignments();
        } catch (err) {
            setError('Failed to assign material: ' + (err.response?.data?.message || err.message));
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateStatus = async (assignmentId, status) => {
        try {
            await axios.put(`/production/inventory/assignment/${assignmentId}`, { status }, { withCredentials: true });
            fetchAssignments();
            fetchRawMaterials();
        } catch (err) {
            setError('Failed to update assignment status: ' + (err.response?.data?.message || err.message));
        }
    };

    const handleNextPage = () => {
        if (pageInfo.hasNextPage) setPage(prev => prev + 1);
    };

    const handlePrevPage = () => {
        if (pageInfo.hasPreviousPage) setPage(prev => prev - 1);
    };

    const handleSort = (key) => {
        const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
        setSortConfig({ key, direction });
        const sorted = [...assignments].sort((a, b) => {
            if (key === 'dateAssigned') return direction === 'asc' ? new Date(a.dateAssigned) - new Date(b.dateAssigned) : new Date(b.dateAssigned) - new Date(a.dateAssigned);
            if (key === 'materialId.name') return direction === 'asc' ? a.materialId.name.localeCompare(b.materialId.name) : b.materialId.name.localeCompare(a.materialId.name);
            if (key === 'quantity') return direction === 'asc' ? a.quantity - b.quantity : b.quantity - a.quantity;
            return 0;
        });
        setAssignments(sorted);
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prev => ({ ...prev, [name]: value }));
        setPage(1); // Reset to first page on filter change
    };

    const clearFilters = () => {
        setFilters({
            startDate: '',
            endDate: '',
            materialName: '',
            assignedTo: '',
            status: '',
            purpose: '',
            batchReference: '',
        });
        setPage(1);
    };

    const activeFilterCount = Object.values(filters).filter(value => value !== '').length;

    return (
        <div className="inventory-page alchemist-vault">
            <div className="vault-header">
                <h3>Material Assignments</h3>
            </div>

            <div className="alchemist-compass">
                <label>Select Store</label>
                <select value={shopDomain} onChange={(e) => setShopDomain(e.target.value)}>
                    {availableStores.map(store => (
                        <option key={store.shopDomain} value={store.shopDomain}>{store.shopDomain}</option>
                    ))}
                </select>
            </div>

            {loading ? (
                <div className="vault-loading">
                    <div className="cauldron-spinner"></div>
                    <span>Loading Assignments...</span>
                </div>
            ) : error ? (
                <div className="vault-error">{error}</div>
            ) : (
                <div className="vault-sections">
                    <section className="assignment-vault">
                        <div className="filter-bar">
                            <div className="filter-item">
                                <select name="status" value={filters.status} onChange={handleFilterChange}>
                                    <option value="">All Statuses</option>
                                    <option value="pending">Pending</option>
                                    <option value="completed">Completed</option>
                                    <option value="cancelled">Cancelled</option>
                                </select>
                            </div>
                            <div className="filter-item">
                                <select name="assignedTo" value={filters.assignedTo} onChange={handleFilterChange}>
                                    <option value="">All Users</option>
                                    {users.map(user => (
                                        <option key={user._id} value={user.email}>
                                            {user.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="filter-item">
                                <input
                                    type="text"
                                    name="materialName"
                                    value={filters.materialName}
                                    onChange={handleFilterChange}
                                    placeholder="Material Name"
                                />
                            </div>
                            <div className="filter-item date-range">
                                <input
                                    type="date"
                                    name="startDate"
                                    value={filters.startDate}
                                    onChange={handleFilterChange}
                                />
                                <span>-</span>
                                <input
                                    type="date"
                                    name="endDate"
                                    value={filters.endDate}
                                    onChange={handleFilterChange}
                                />
                            </div>
                            <div className="filter-item">
                                <input
                                    type="text"
                                    name="purpose"
                                    value={filters.purpose}
                                    onChange={handleFilterChange}
                                    placeholder="Purpose"
                                />
                            </div>
                            <div className="filter-item">
                                <input
                                    type="text"
                                    name="batchReference"
                                    value={filters.batchReference}
                                    onChange={handleFilterChange}
                                    placeholder="Batch Reference"
                                />
                            </div>
                            {activeFilterCount > 0 && (
                                <button className="clear-filter-btn" onClick={clearFilters}>
                                    Clear {activeFilterCount} Filter{activeFilterCount > 1 ? 's' : ''}
                                </button>
                            )}
                        </div>
                        <div className="section-controls">
                            <button className="forge-btn" onClick={() => setShowAssignModal(true)}>Assign Material</button>
                            <div className="sort-controls">
                                <label>Sort By:</label>
                                <select
                                    value={`${sortConfig.key}-${sortConfig.direction}`}
                                    onChange={(e) => {
                                        const [key, direction] = e.target.value.split('-');
                                        handleSort(key);
                                    }}
                                >
                                    <option value="dateAssigned-desc">Date (Newest First)</option>
                                    <option value="dateAssigned-asc">Date (Oldest First)</option>
                                    <option value="materialId.name-asc">Material Name (A-Z)</option>
                                    <option value="materialId.name-desc">Material Name (Z-A)</option>
                                    <option value="quantity-asc">Quantity (Low to High)</option>
                                    <option value="quantity-desc">Quantity (High to Low)</option>
                                </select>
                            </div>
                        </div>
                        <div className="assignment-list">
                            {assignments.map(assignment => (
                                <div key={assignment._id} className="assignment-card">
                                    <div className="assignment-header">
                                        <h4>{assignment.materialId?.name || 'N/A'}</h4>
                                        <span className={`status-badge ${assignment.status.toLowerCase()}`}>
                                            {assignment.status}
                                        </span>
                                    </div>
                                    <div className="assignment-body">
                                        <div className="assignment-info">
                                            <p><strong>Unit:</strong> {assignment.materialId?.unit || 'N/A'}</p>
                                            <p><strong>Assigned To:</strong> {assignment.assignedTo?.name || 'N/A'} ({assignment.assignedTo?.email || 'N/A'})</p>
                                            <p><strong>Quantity:</strong> {assignment.quantity}</p>
                                            <p><strong>Purpose:</strong> {assignment.purpose}</p>
                                            <p><strong>Date:</strong> {new Date(assignment.dateAssigned).toLocaleDateString()}</p>
                                            {assignment.notes && <p><strong>Notes:</strong> {assignment.notes}</p>}
                                            {assignment.batchReference && <p><strong>Batch:</strong> {assignment.batchReference}</p>}
                                        </div>
                                    </div>
                                    <div className="assignment-footer">
                                        {assignment.status === 'pending' && (
                                            <div className="assignment-actions">
                                                <button className="adjust-btn" onClick={() => handleUpdateStatus(assignment._id, 'completed')}>
                                                    Mark Completed
                                                </button>
                                                <button className="crucible-btn" onClick={() => handleUpdateStatus(assignment._id, 'cancelled')}>
                                                    Cancel
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="vault-runes">
                            <button className="rune-btn" onClick={handlePrevPage} disabled={!pageInfo.hasPreviousPage || loading}>
                                ←
                            </button>
                            <span className="rune-page">Page {page} of {pageInfo.totalPages}</span>
                            <button className="rune-btn" onClick={handleNextPage} disabled={!pageInfo.hasNextPage || loading}>
                                →
                            </button>
                        </div>
                    </section>
                </div>
            )}

            {/* Assign Material Modal */}
            <div className={`grimoire-modal ${showAssignModal ? 'active' : ''}`}>
                <div className="grimoire-overlay" onClick={() => setShowAssignModal(false)}></div>
                <div className="grimoire-content">
                    <button className="grimoire-close" onClick={() => setShowAssignModal(false)}>✕</button>
                    <h3>Assign Material</h3>
                    <div className="grimoire-page">
                        <label>Material</label>
                        <select
                            value={assignmentData.materialId}
                            onChange={(e) => setAssignmentData({ ...assignmentData, materialId: e.target.value })}
                        >
                            <option value="">Select Material</option>
                            {rawMaterials.map(material => (
                                <option key={material._id} value={material._id}>
                                    {material.name} ({material.quantity} {material.unit} available)
                                </option>
                            ))}
                        </select>
                        <label>Assign To</label>
                        <select
                            value={assignmentData.assignedToEmail}
                            onChange={(e) => setAssignmentData({ ...assignmentData, assignedToEmail: e.target.value })}
                        >
                            <option value="">Select User</option>
                            {users.length > 0 ? (
                                users.map(user => (
                                    <option key={user._id} value={user.email}>
                                        {user.name} ({user.email})
                                    </option>
                                ))
                            ) : (
                                <option value="" disabled>No users available</option>
                            )}
                        </select>
                        <label>Quantity</label>
                        <input
                            type="number"
                            value={assignmentData.quantity}
                            onChange={(e) => setAssignmentData({ ...assignmentData, quantity: parseInt(e.target.value) || 0 })}
                            min="1"
                        />
                        <label>Purpose</label>
                        <input
                            type="text"
                            value={assignmentData.purpose}
                            onChange={(e) => setAssignmentData({ ...assignmentData, purpose: e.target.value })}
                            placeholder="e.g., Daily Production"
                        />
                        <label>Notes (Optional)</label>
                        <textarea
                            value={assignmentData.notes}
                            onChange={(e) => setAssignmentData({ ...assignmentData, notes: e.target.value })}
                            placeholder="Additional notes"
                        />
                        <label>Batch Reference (Optional)</label>
                        <input
                            type="text"
                            value={assignmentData.batchReference}
                            onChange={(e) => setAssignmentData({ ...assignmentData, batchReference: e.target.value })}
                            placeholder="e.g., Batch #123"
                        />
                        {error && <div className="vault-error">{error}</div>}
                        <button className="inscribe-btn" onClick={handleAssignMaterial}>Assign</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MaterialAssignmentPage;