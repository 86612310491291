/* react_frontend/src/context/AuthContext.js */
import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    // Check session validity on mount and periodically
    useEffect(() => {
        const validateSession = async () => {
            try {
                const response = await axios.get('/me', { withCredentials: true });
                const userData = {
                    name: response.data.name,
                    role: response.data.role, // Assuming backend returns role
                };
                setUser(userData);
                localStorage.setItem('userName', userData.name);
            } catch (error) {
                console.error('Session validation failed:', error);
                setUser(null);
                localStorage.removeItem('userName');
            } finally {
                setLoading(false);
            }
        };

        validateSession();

        // Optional: Periodically validate session (e.g., every 5 minutes)
        const interval = setInterval(validateSession, 5 * 60 * 1000); // 5 minutes
        return () => clearInterval(interval);
    }, []);

    const login = async (userData) => {
        try {
            const response = await axios.get('/me', { withCredentials: true }); // Fetch user details after OTP verification
            const fullUserData = {
                name: userData.name,
                role: response.data.role, // Include role from backend
            };
            localStorage.setItem('userName', fullUserData.name);
            setUser(fullUserData);
        } catch (error) {
            console.error('Failed to fetch user data after login:', error);
            setUser(null);
            localStorage.removeItem('userName');
        }
    };

    const logout = async () => {
        try {
            await axios.post('/logout', {}, { withCredentials: true });
        } catch (error) {
            console.error('Logout failed:', error);
        }
        localStorage.clear();
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);