import { getToken, handleApiResponse } from '../utils/commonUtils'

export const fetchShipments = async () => {
  const response = await fetch('/api/shipments', {
    credentials: 'include',
  });
  return handleApiResponse(response);
};

export const deleteShipment = async (trackingId) => {
  const response = await fetch(`/api/shipments/${trackingId}`, {
    method: 'DELETE',
    credentials: 'include',
  });
  return handleApiResponse(response);
};

export const fetchShipmentDetails = async (trackingId) => {
  const response = await fetch(`/api/shipments/${trackingId}`, {
    credentials: 'include',
  });
  return handleApiResponse(response);
};

export const addComment = async (trackingId, comment) => {
  const response = await fetch(`/api/shipments/${trackingId}/comments`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(comment),
  });
  return handleApiResponse(response);
};
