/* react_frontend/src/App.js */
import React from 'react';
import { Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import { ThemeProvider } from './context/ThemeContext';
import DashboardLayout from './layouts/DashboardLayout';
import LoginPage from './pages/LoginPage';
import RegistrationPage from './pages/RegistrationPage';
import HomePage from './pages/HomePage';
import SettingsPage from './pages/SettingsPage';

// Store Pages
import ShipmentPage from './pages/Store/ShipmentPage';
import OrdersPage from './pages/Store/OrdersPage';
import StoreInventoryPage from './pages/Store/StoreInventoryPage';
import InventoryPredictionPage from './pages/Store/InventoryPredictionPage';
import LabourEfficiencyPage from './pages/Store/LabourEfficiencyPage';

// Production Pages
import ProductionProgressPage from './pages/Production/ProductionProgressPage';
import ProductionInventoryPage from './pages/Production/ProductionInventoryPage';
import ProductionDashboard from './pages/Production/ProductionDashboard';
import MaterialAssignmentPage from './pages/Production/MaterialAssignmentPage';

import CustomerRegistrationPage from './pages/CustomerRegistrationPage';

const ProtectedRoute = ({ allowedRoles }) => {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <div className="loading-overlay">Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (allowedRoles && !allowedRoles.includes(user.role)) {
    return <Navigate to="/home" replace />;
  }

  return <Outlet />;
};

const PublicRoute = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div className="loading-overlay">Loading...</div>;
  }

  return user ? <Navigate to="/home" replace /> : <Outlet />;
};

const AppRoutes = () => {
  return (
    <Routes>
      {/* Public Routes: Accessible without authentication */}
      <Route element={<PublicRoute />}>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegistrationPage />} />
        <Route path="/summit" element={<CustomerRegistrationPage />} />
      </Route>

      {/* Protected Routes: Require authentication */}
      <Route element={<ProtectedRoute />}>
        <Route element={<DashboardLayout />}>
          {/* General Access Pages */}
          {/* Home: Accessible to all authenticated users */}
          <Route path="/home" element={<HomePage />} />

          {/* Role-Specific Pages */}
          {/* Store Routes */}

          {/* Shipments: For managing RTO */}
          <Route
            element={<ProtectedRoute allowedRoles={['admin', 'order_manager', 'operation_manager', 'customer_supprt', 'rto_execitive', 'order_executive']} />}
          >
            <Route path="/store/shipments" element={<ShipmentPage />} />
          </Route>

          {/* Orders: For managing order fulfillment and packaging */}
          <Route
            element={<ProtectedRoute allowedRoles={['admin', 'order_manager', 'packaging_executive', 'order_executive', 'operation_manager', 'customer_supprt', 'rto_execitive']} />}
          >
            <Route path="/store/orders" element={<OrdersPage />} />
          </Route>

          {/* Inventory: For inventory management */}
          <Route
            element={<ProtectedRoute allowedRoles={['admin', 'inventory_manager', 'inventory_executive', 'operation_manager']} />}
          >
            <Route path="/store/inventory" element={<StoreInventoryPage />} />
          </Route>

          {/* Inventory Predictions: For forecasting inventory needs */}
          <Route
            element={<ProtectedRoute allowedRoles={['admin', 'inventory_manager', 'operation_manager']} />}
          >
            <Route path="/store/prediction" element={<InventoryPredictionPage />} />
          </Route>

          {/* Inventory Predictions: For forecasting inventory needs */}
          <Route
            element={<ProtectedRoute allowedRoles={['admin', 'operation_manager']} />}
          >
            <Route path="/store/efficiency" element={<LabourEfficiencyPage />} />
          </Route>

          {/* Production Routes */}
          <Route
            element={<ProtectedRoute allowedRoles={['admin', 'inventory_manager', 'inventory_executive', 'operation_manager', 'production_manager']} />}
          >
            <Route path="/production/inventory" element={<ProductionInventoryPage />} />
          </Route>
          <Route
            element={<ProtectedRoute allowedRoles={['admin', 'production_manager', 'operation_manager']} />}
          >
            <Route path="/production/progress" element={<ProductionProgressPage />} />
          </Route>
          <Route
            element={<ProtectedRoute allowedRoles={['admin', 'production_manager', 'operation_manager']} />}
          >
            <Route path="/production/planning" element={<ProductionDashboard />} /> {/* Assuming 'planning' replaces 'production' */}
          </Route>
          <Route
            element={<ProtectedRoute allowedRoles={['admin', 'operation_manager']} />}
          >
            <Route path="/production/efficiency" element={<LabourEfficiencyPage />} />
          </Route>

          <Route
            element={<ProtectedRoute allowedRoles={['admin', 'operation_manager']} />}
          >
            <Route path="/production/assignments" element={<MaterialAssignmentPage />} />
          </Route>

          {/* Settings: Admin-only */}
          <Route
            element={<ProtectedRoute allowedRoles={['admin']} />}
          >
            <Route path="/settings" element={<SettingsPage />} />
          </Route>

          {/* Default redirect to home */}
          <Route index element={<Navigate to="/home" replace />} />
        </Route>
      </Route>

      {/* Catch-all redirect to login */}
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <ThemeProvider>
        <AppRoutes />
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;