// react_frontend/src/pages/InventoryPredictionPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import '../InventoryPredictionPage.css';

// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const InventoryPredictionPage = () => {
    const [predictions, setPredictions] = useState({
        highSellingLowStock: [],
        overstocked: [],
        reorderRecommendations: [],
        rawMaterials: [],
    });
    const [steps, setSteps] = useState({
        checkBulk: { status: 'pending', bulkOperationId: null },
        fetchOrders: { status: 'pending', bulkOperationId: null },
        fetchInventory: { status: 'pending', bulkOperationId: null },
        generatePredictions: { status: 'pending' },
        fetchRawMaterials: { status: 'pending' },
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [shopDomain, setShopDomain] = useState('');
    const [stores, setStores] = useState([]);
    const [filters, setFilters] = useState({ type: 'all', search: '' });
    const [sortField, setSortField] = useState('daysLeft');
    const [sortOrder, setSortOrder] = useState('asc');

    useEffect(() => {
        fetchStores();
    }, []);

    const fetchStores = async () => {
        try {
            const response = await axios.get('/shopify/stores', { withCredentials: true });
            setStores(response.data.stores || []);
            if (response.data.stores?.length > 0) {
                setShopDomain(response.data.stores[0].shopDomain);
                startPrediction(response.data.stores[0].shopDomain);
            }
        } catch (err) {
            setError('Failed to fetch stores: ' + (err.response?.data?.message || err.message));
        }
    }

    const startPrediction = async (selectedShopDomain = shopDomain) => {
        setLoading(true);
        setError('');
        setSteps({
            checkBulk: { status: 'running', bulkOperationId: null },
            fetchOrders: { status: 'pending', bulkOperationId: null },
            fetchInventory: { status: 'pending', bulkOperationId: null },
            generatePredictions: { status: 'pending' },
            fetchRawMaterials: { status: 'pending' },
        });

        try {
            const checkResponse = await axios.get(`/inventory/check-bulk?shopDomain=${selectedShopDomain}`, { withCredentials: true });
            if (checkResponse.data.running) {
                setSteps(prev => ({ ...prev, checkBulk: { status: 'blocked', bulkOperationId: checkResponse.data.bulkOperationId } }));
                setError('A bulk operation is already in progress. Cancel or wait.');
                setLoading(false);
                return;
            }
            setSteps(prev => ({ ...prev, checkBulk: { status: 'completed', bulkOperationId: null } }));

            setSteps(prev => ({ ...prev, fetchOrders: { status: 'running' } }));
            const orderResponse = await axios.get(`/inventory/fetch-orders?shopDomain=${selectedShopDomain}`, { withCredentials: true });
            setSteps(prev => ({ ...prev, fetchOrders: { status: 'completed', bulkOperationId: orderResponse.data.bulkOperationId } }));

            setSteps(prev => ({ ...prev, fetchInventory: { status: 'running' } }));
            const inventoryResponse = await axios.get(`/inventory/fetch-inventory?shopDomain=${selectedShopDomain}`, { withCredentials: true });
            setSteps(prev => ({ ...prev, fetchInventory: { status: 'completed', bulkOperationId: inventoryResponse.data.bulkOperationId } }));

            setSteps(prev => ({ ...prev, generatePredictions: { status: 'running' } }));
            const predictionResponse = await axios.get(`/inventory/generate-predictions?shopDomain=${selectedShopDomain}`, { withCredentials: true });
            setSteps(prev => ({ ...prev, generatePredictions: { status: 'completed' } }));

            setSteps(prev => ({ ...prev, fetchRawMaterials: { status: 'running' } }));
            const rawMaterialsResponse = await axios.get(`/inventory/fetch-raw-materials?shopDomain=${selectedShopDomain}`, { withCredentials: true });
            setSteps(prev => ({ ...prev, fetchRawMaterials: { status: 'completed' } }));

            setPredictions({
                highSellingLowStock: predictionResponse.data.highSellingLowStock || [],
                overstocked: predictionResponse.data.overstocked || [],
                reorderRecommendations: predictionResponse.data.reorderRecommendations || [],
                rawMaterials: rawMaterialsResponse.data.rawMaterials || [],
            });
        } catch (err) {
            const stepMatch = err.config?.url?.match(/\/inventory\/([^?]+)/);
            const step = stepMatch ? stepMatch[1] : 'checkBulk';
            setSteps(prev => ({
                ...prev,
                [step]: {
                    status: err.response?.status === 429 ? 'blocked' : 'error',
                    bulkOperationId: err.response?.data?.bulkOperationId || prev[step]?.bulkOperationId || null,
                },
            }));
            setError(`Step ${step} failed: ${err.response?.data?.message || err.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleCancelBulk = async (stepKey) => {
        const bulkOperationId = steps[stepKey]?.bulkOperationId;
        if (!bulkOperationId) return;

        setLoading(true);
        try {
            await axios.post('/inventory/cancel-bulk', { shopDomain, bulkOperationId }, { withCredentials: true });
            setSteps(prev => ({ ...prev, [stepKey]: { status: 'canceled', bulkOperationId: null } }));
            setError(`Step ${stepKey} canceled. Restart to proceed.`);
        } catch (err) {
            setError('Failed to cancel bulk operation: ' + (err.response?.data?.message || err.message));
        } finally {
            setLoading(false);
        }
    };

    const handleRestartStep = (stepKey) => {
        const stepMap = {
            checkBulk: () => axios.get(`/inventory/check-bulk?shopDomain=${shopDomain}`, { withCredentials: true }),
            fetchOrders: () => axios.get(`/inventory/fetch-orders?shopDomain=${shopDomain}`, { withCredentials: true }),
            fetchInventory: () => axios.get(`/inventory/fetch-inventory?shopDomain=${shopDomain}`, { withCredentials: true }),
            generatePredictions: () => axios.get(`/inventory/generate-predictions?shopDomain=${shopDomain}`, { withCredentials: true }),
            fetchRawMaterials: () => axios.get(`/inventory/fetch-raw-materials?shopDomain=${shopDomain}`, { withCredentials: true }),
        };

        setLoading(true);
        stepMap[stepKey]()
            .then(response => {
                setSteps(prev => ({
                    ...prev,
                    [stepKey]: { status: 'completed', bulkOperationId: response.data.bulkOperationId || null },
                }));
                if (stepKey === 'generatePredictions') {
                    setPredictions(prev => ({
                        ...prev,
                        highSellingLowStock: response.data.highSellingLowStock || [],
                        overstocked: response.data.overstocked || [],
                        reorderRecommendations: response.data.reorderRecommendations || [],
                    }));
                } else if (stepKey === 'fetchRawMaterials') {
                    setPredictions(prev => ({ ...prev, rawMaterials: response.data.rawMaterials || [] }));
                }
            })
            .catch(err => {
                setSteps(prev => ({
                    ...prev,
                    [stepKey]: {
                        status: err.response?.status === 429 ? 'blocked' : 'error',
                        bulkOperationId: err.response?.data?.bulkOperationId || steps[stepKey]?.bulkOperationId || null,
                    },
                }));
                setError(`Restarting ${stepKey} failed: ${err.response?.data?.message || err.message}`);
            })
            .finally(() => setLoading(false));
    };

    const handleExport = async () => {
        try {
            const response = await axios.get(`/inventory/export-predictions?shopDomain=${shopDomain}`, {
                withCredentials: true,
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `inventory_predictions_${shopDomain}_${Date.now()}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (err) {
            setError('Failed to export predictions: ' + (err.response?.data?.message || err.message));
        }
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prev => ({ ...prev, [name]: value }));
    };

    const handleSort = (field) => {
        setSortField(field);
        setSortOrder(sortField === field && sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const getFilteredPredictions = () => {
        try {
            const allItems = [
                ...predictions.highSellingLowStock.map(i => ({ ...i, type: 'highSellingLowStock' })),
                ...predictions.overstocked.map(i => ({ ...i, type: 'overstocked' })),
                ...predictions.reorderRecommendations.map(i => ({ ...i, type: 'reorderRecommendations' })),
                ...predictions.rawMaterials.map(i => ({ ...i, type: 'rawMaterials' })),
            ];

            return allItems
                .filter(item =>
                    (filters.type === 'all' || item.type === filters.type) &&
                    (filters.search === '' ||
                        item.sku?.toString().toLowerCase().includes(filters.search.toLowerCase()) ||
                        item.product?.toString().toLowerCase().includes(filters.search.toLowerCase()) ||
                        item.name?.toString().toLowerCase().includes(filters.search.toLowerCase()))
                )
                .sort((a, b) => {
                    const valueA = a[sortField] ?? '';
                    const valueB = b[sortField] ?? '';
                    if (typeof valueA === 'number' && typeof valueB === 'number') {
                        return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
                    }
                    return sortOrder === 'asc'
                        ? String(valueA).localeCompare(String(valueB))
                        : String(valueB).localeCompare(String(valueA));
                });
        } catch (error) {
            console.error('Error in getFilteredPredictions:', error);
            return [];
        }
    };

    const filteredData = getFilteredPredictions();

    const chartData = {
        labels: filteredData.map(i => i.sku || i.id || 'Unknown'),
        datasets: [
            {
                label: 'Stock',
                data: filteredData.map(i => Number(i.stock) || 0),
                borderColor: '#4c51bf',
                backgroundColor: 'rgba(76, 81, 191, 0.2)',
                fill: false,
                tension: 0.1,
            },
            {
                label: 'Reorder Point',
                data: filteredData.map(i => Number(i.reorderPoint) || 0),
                borderColor: '#dc3545',
                backgroundColor: 'rgba(220, 53, 69, 0.2)',
                fill: false,
                tension: 0.1,
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Inventory Levels vs Reorder Points',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Quantity'
                }
            },
            x: {
                title: {
                    display: true,
                    text: 'Items'
                }
            }
        }
    };

    return (
        <div className="inventory-prediction-page astral-observatory">
            <div className="diviner-lens">
                <h3>Inventory Predictions</h3>
                {stores.length > 1 && (
                    <select
                        value={shopDomain}
                        onChange={e => {
                            setShopDomain(e.target.value);
                            startPrediction(e.target.value);
                        }}
                        disabled={loading}
                    >
                        {stores.map(store => (
                            <option key={store.shopDomain} value={store.shopDomain}>
                                {store.shopDomain}
                            </option>
                        ))}
                    </select>
                )}
                <div className="controls">
                    <select name="type" value={filters.type} onChange={handleFilterChange}>
                        <option value="all">All</option>
                        <option value="highSellingLowStock">High Demand, Low Stock</option>
                        <option value="overstocked">Overstocked</option>
                        <option value="reorderRecommendations">Reorder Recommendations</option>
                        <option value="rawMaterials">Raw Materials</option>
                    </select>
                    <input
                        type="text"
                        name="search"
                        value={filters.search}
                        onChange={handleFilterChange}
                        placeholder="Search by SKU or Name"
                    />
                    <button onClick={handleExport} disabled={loading}>Export CSV</button>
                </div>
            </div>

            <div className="step-tracker">
                {Object.entries(steps).map(([key, { status, bulkOperationId }]) => (
                    <div key={key} className={`step ${status}`}>
                        <span>{key.replace(/([A-Z])/g, ' $1').trim()}</span>
                        <span className="status">{status}</span>
                        {(status === 'blocked' || status === 'error') && bulkOperationId && (
                            <button onClick={() => handleCancelBulk(key)} disabled={loading}>Cancel</button>
                        )}
                        {(status === 'canceled' || status === 'error') && (
                            <button onClick={() => handleRestartStep(key)} disabled={loading}>Restart</button>
                        )}
                    </div>
                ))}
            </div>

            {loading && (
                <div className="star-loading">
                    <div className="orbit-diviner"></div>
                    <span>Consulting the Celestial Charts...</span>
                </div>
            )}
            {error && <div className="diviner-error">{error}</div>}

            {!loading && !error && steps.fetchRawMaterials.status === 'completed' && filteredData.length > 0 && (
                <>
                    <section className="prediction-section" style={{ height: '400px' }}>
                        <h4>Inventory Overview</h4>
                        <div style={{ position: 'relative', height: '100%' }}>
                            <Line data={chartData} options={chartOptions} />
                        </div>
                    </section>

                    <section className="prediction-section">
                        <h4>Detailed Predictions</h4>
                        <table className="prediction-table">
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort('type')}>Type</th>
                                    <th onClick={() => handleSort('sku')}>SKU/ID</th>
                                    <th onClick={() => handleSort('product')}>Product/Name</th>
                                    <th onClick={() => handleSort('stock')}>Stock</th>
                                    <th onClick={() => handleSort('avgDailySales')}>Daily Sales/Usage</th>
                                    <th onClick={() => handleSort('daysLeft')}>Days Left</th>
                                    <th onClick={() => handleSort('safetyStock')}>Safety Stock</th>
                                    <th onClick={() => handleSort('reorderPoint')}>Reorder Point</th>
                                    <th onClick={() => handleSort('recommendedOrder')}>Recommended Order</th>
                                    <th onClick={() => handleSort('orderByDate')}>Order By</th>
                                    <th onClick={() => handleSort('excessUnits')}>Excess Units</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map(item => (
                                    <tr key={item.sku || item.id || Math.random().toString()}>
                                        <td>{item.type || 'N/A'}</td>
                                        <td>{item.sku || item.id || 'N/A'}</td>
                                        <td>{item.product || item.name || 'N/A'}</td>
                                        <td>{item.stock ?? 'N/A'}</td>
                                        <td>{item.avgDailySales || item.avgDailyUsage || 'N/A'}</td>
                                        <td>{item.daysLeft ?? 'N/A'}</td>
                                        <td>{item.safetyStock ?? 'N/A'}</td>
                                        <td>{item.reorderPoint ?? 'N/A'}</td>
                                        <td>{item.recommendedOrder || '-'}</td>
                                        <td>{item.orderByDate || '-'}</td>
                                        <td>{item.excessUnits || '-'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </section>
                </>
            )}
        </div>
    );
};

export default InventoryPredictionPage;