/* react_frontend/src/pages/LoginPage.js */
import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';

const LoginPage = () => {
    const { login } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [step, setStep] = useState('request');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleRequestOtp = async () => {
        try {
            await axios.post('/request-otp', { email }, { withCredentials: true });
            setStep('verify');
            setSuccess('OTP sent successfully');
            setError('');
        } catch (error) {
            setError('Failed to send OTP');
        }
    };

    const handleVerifyOtp = async () => {
        try {
            const response = await axios.post('/verify-otp', { email, otp }, { withCredentials: true });
            const userData = {
                name: response.data.name,
                role: response.data.role, // Ensure backend returns role
            };
            login(userData); // Pass full user data with role
            setSuccess('Login successful!');
            const redirectTo = location.state?.from?.pathname || '/shipments';
            navigate(redirectTo);
        } catch (error) {
            setError('Invalid OTP');
        }
    };

    return (
        <div className="login-container">
            <div className="login-card">
                <h2>HealthFab Employee Login</h2>
                {step === 'request' ? (
                    <>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="mb-3"
                        />
                        <Button onClick={handleRequestOtp} variant="primary" className="w-100">
                            Send OTP
                        </Button>
                    </>
                ) : (
                    <>
                        <Form.Control
                            type="text"
                            placeholder="Enter OTP"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            className="mb-3"
                        />
                        <Button onClick={handleVerifyOtp} variant="primary" className="w-100">
                            Verify OTP
                        </Button>
                    </>
                )}
                {error && <div className="text-danger mt-3">{error}</div>}
                {success && <div className="text-success mt-3">{success}</div>}
            </div>
        </div>
    );
};

export default LoginPage;