/* react_frontend//src/components/ShipmentSummary.js */

import React from 'react';

const ShipmentSummary = ({ shipments, logisticsCount, logisticsValue, totalValue }) => (
  <div className="flight-ticker mb-5">
    <div className="ticker-card total-card">
      <h3>Total Value</h3>
      <div className="value">₹{totalValue.toFixed(0)}</div>
      <div className="count">{shipments.length} Shipments</div>
    </div>
    {Object.keys(logisticsCount).map((logistic) => (
      <div key={logistic} className="ticker-card">
        <h3>{logistic}</h3>
        <div className="value">₹{logisticsValue[logistic].toFixed(0)}</div>
        <div className="count">{logisticsCount[logistic]}</div>
      </div>
    ))}
  </div>
);

export default ShipmentSummary;