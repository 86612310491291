/* react_frontend/src/pages/RegistrationPage.js */
import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const RegistrationPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleRegister = async () => {
        try {
            // Send registration data to backend
            const response = await axios.post('/register', {
                email,
                phoneNumber,
                name,
                password,
            });

            // Assuming backend returns a success message or user data
            setSuccess('Registration successful! Redirecting to login...');
            setError('');

            // Redirect to login page after a short delay
            setTimeout(() => {
                navigate('/login');
            }, 2000); // 2-second delay for user to see success message
        } catch (error) {
            // Handle error from backend (e.g., duplicate email, validation failure)
            setError(error.response?.data?.message || 'Registration failed');
            setSuccess('');
        }
    };

    return (
        <div className="login-container">
            <div className="login-card">
                <h2>Register for Dashboard</h2>
                <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="mb-3"
                />
                <Form.Control
                    type="text"
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="mb-3"
                />
                <Form.Control
                    type="text"
                    placeholder="Enter name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mb-3"
                />
                <Form.Control
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="mb-3"
                />
                <Button onClick={handleRegister} variant="primary" className="w-100">
                    Register
                </Button>
                {error && <div className="text-danger mt-3">{error}</div>}
                {success && <div className="text-success mt-3">{success}</div>}
            </div>
        </div>
    );
};

export default RegistrationPage;