/* react_frontend/src/layouts/DashboardLayout.js */

import React, { useState, useRef } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { useAuth } from '../context/AuthContext';
import { FaShippingFast, FaBoxOpen, FaCubes, FaChartLine, FaIndustry, FaUsers, FaBars, FaCog, FaStore, FaTools, FaSortAmountUp } from 'react-icons/fa';
import './DashboardLayout.css';

const DashboardLayout = () => {
    const { user, logout } = useAuth();
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const [sidebarOpen, setSidebarOpen] = useState(!isMobile);
    const [openMenu, setOpenMenu] = useState(null);
    const navigate = useNavigate();
    const menuRefs = useRef({});

    const categories = [
        {
            name: 'Store',
            key: 'store',
            icon: <FaStore />,
            submenu: [
                { path: '/store/orders', name: 'Orders', icon: <FaBoxOpen /> },
                { path: '/store/shipments', name: 'Shipments', icon: <FaShippingFast /> },
                { path: '/store/inventory', name: 'Inventory', icon: <FaCubes /> },
                { path: '/store/prediction', name: 'Predictions', icon: <FaChartLine /> },
                { path: '/store/efficiency', name: 'Efficiency', icon: <FaUsers /> },
            ],
        },
        {
            name: 'Production',
            key: 'production',
            icon: <FaIndustry />,
            submenu: [
                { path: '/production/inventory', name: 'Inventory', icon: <FaCubes /> },
                { path: '/production/progress', name: 'Progress', icon: <FaSortAmountUp /> },
                { path: '/production/planning', name: 'Planning', icon: <FaChartLine /> },
                { path: '/production/efficiency', name: 'Efficiency', icon: <FaUsers /> },
                { path: '/production/assignments', name: 'Assignments', icon: <FaUsers /> },
            ],
        },
        {
            name: 'Settings',
            key: 'settings',
            icon: <FaCog />,
            path: '/settings',
        },
    ];

    const toggleSidebar = () => setSidebarOpen(prev => !prev);

    const handleCategoryClick = (category) => {
        if (category.path) {
            navigate(category.path);
            if (isMobile) setSidebarOpen(false);
        } else {
            setOpenMenu(openMenu === category.key ? null : category.key);
        }
    };

    const handleMouseEnter = (key) => {
        if (!isMobile) setOpenMenu(key);
    };

    const handleMouseLeave = () => {
        if (!isMobile) setOpenMenu(null);
    };

    return (
        <Container fluid className="p-0 dashboard-layout cosmic-spire">
            <Row className="m-0">
                <Col
                    xs={sidebarOpen ? 12 : 0}
                    md={sidebarOpen ? 3 : 1}
                    lg={sidebarOpen ? 2 : 1}
                    className={`spire-nav ${sidebarOpen ? 'spire-open' : 'spire-collapsed'}`}
                >
                    <div className="spire-beacon">
                        {sidebarOpen ? <h4>Olinwan</h4> : <div className="spire-icon">✨</div>}
                        <FaBars className="hamburger-toggle" onClick={toggleSidebar} />
                    </div>
                    <div className="spire-glyphs">
                        {categories.map((category) => (
                            <div
                                key={category.key}
                                className="glyph-item"
                                ref={(el) => (menuRefs.current[category.key] = el)}
                                onMouseEnter={() => handleMouseEnter(category.key)}
                                onMouseLeave={handleMouseLeave}
                                onClick={() => handleCategoryClick(category)}
                            >
                                <div className="glyph-header">
                                    <div className="glyph-icon">{category.icon}</div>
                                    {sidebarOpen && (
                                        <div className="glyph-text">
                                            {category.path ? (
                                                <Link to={category.path} className="glyph-link">
                                                    {category.name}
                                                </Link>
                                            ) : (
                                                category.name
                                            )}
                                        </div>
                                    )}
                                </div>
                                {category.submenu && openMenu === category.key && (
                                    <div
                                        className={`submenu ${sidebarOpen ? 'expanded' : 'collapsed'}`}
                                        style={!sidebarOpen ? { top: menuRefs.current[category.key]?.querySelector('.glyph-header').offsetTop || 0 } : {}}
                                    >
                                        {category.submenu.map((item) => (
                                            <Link
                                                to={item.path}
                                                key={item.path}
                                                className="submenu-item"
                                                onClick={() => isMobile && setSidebarOpen(false)}
                                            >
                                                <div className="glyph-icon">{item.icon}</div>
                                                <div className="glyph-text">{item.name}</div>
                                            </Link>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    <button className="comet-logout" onClick={logout}>Logout</button>
                </Col>

                <Col className="spire-content">
                    <nav className="spire-navbar">
                        <div className="welcome-orbit">
                            {isMobile && !sidebarOpen && (
                                <FaBars className="hamburger-toggle mobile-toggle" onClick={toggleSidebar} />
                            )}
                            <span>Welcome, {user?.name || 'Navigator'}</span>
                        </div>
                    </nav>
                    <div className="content-vault">
                        <Outlet />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default DashboardLayout;