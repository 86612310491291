/* react_frontend/src/pages/Store/ShipmentPage.js */
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import ShipmentTable from '../../components/ShipmentTable';
import ShipmentModal from '../../components/ShipmentModal';
import ShipmentSummary from '../../components/ShipmentSummary';
import {
    fetchShipments,
    fetchShipmentDetails,
    addComment,
} from '../../services/shipmentService';
import {
    processShipmentsData,
    fetchAllShipmentDetails,
} from '../../utils/shipmentUtils';
import { jsonToCsv, downloadCsv } from '../../utils/csvUtils';
import { useAuth } from '../../context/AuthContext';
import './ShipmentPage.css';

const ShipmentPage = () => {
    const { user } = useAuth();
    const [shipments, setShipments] = useState([]);
    const [filteredShipments, setFilteredShipments] = useState([]);
    const [logisticsCount, setLogisticsCount] = useState({});
    const [logisticsValue, setLogisticsValue] = useState({});
    const [totalValue, setTotalValue] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [shipmentDetails, setShipmentDetails] = useState(null);
    const [newComment, setNewComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false); // Filter section collapsed by default

    // Filter states
    const [dateRange, setDateRange] = useState({ start: '', end: '' });
    const [selectedLogistic, setSelectedLogistic] = useState('');
    const [selectedCommenter, setSelectedCommenter] = useState('');
    const [selectedOrderType, setSelectedOrderType] = useState('');

    // Unique filter options
    const [logisticsOptions, setLogisticsOptions] = useState([]);
    const [commenterOptions, setCommenterOptions] = useState([]);
    const [orderTypeOptions, setOrderTypeOptions] = useState([]);

    useEffect(() => {
        fetchShipmentsData();
    }, [user]);

    const fetchShipmentsData = async () => {
        setLoading(true);
        try {
            const data = await fetchShipments();
            const {
                updatedShipments,
                newLogisticsCount,
                newLogisticsValue,
                totalValue,
            } = processShipmentsData(data);
            setShipments(updatedShipments);
            setFilteredShipments(updatedShipments);
            setLogisticsCount(newLogisticsCount);
            setLogisticsValue(newLogisticsValue);
            setTotalValue(totalValue);

            setLogisticsOptions([...new Set(updatedShipments.map(s => s.shipment.logistic))]);
            // Add "No Comments" option to commenterOptions
            const commenters = [
                ...new Set(
                    updatedShipments
                        .flatMap(s => s.shipment.comments || [])
                        .map(c => c.commenter_name)
                        .filter(Boolean)
                ),
            ];
            setCommenterOptions([...commenters, 'No Comments']);
            setOrderTypeOptions([...new Set(updatedShipments.map(s => s.shipment.order_details.order_type))]);
        } catch (error) {
            console.error('Error fetching shipments:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        applyFilters();
    }, [dateRange, selectedLogistic, selectedCommenter, selectedOrderType, shipments]);

    const applyFilters = () => {
        let filtered = [...shipments];

        if (dateRange.start || dateRange.end) {
            filtered = filtered.filter(shipment => {
                const createdAt = new Date(shipment.createdAt);
                const start = dateRange.start ? new Date(dateRange.start) : null;
                const end = dateRange.end ? new Date(dateRange.end) : null;
                return (
                    (!start || createdAt >= start) &&
                    (!end || createdAt <= end)
                );
            });
        }

        if (selectedLogistic) {
            filtered = filtered.filter(shipment => shipment.shipment.logistic === selectedLogistic);
        }

        if (selectedCommenter) {
            if (selectedCommenter === 'No Comments') {
                filtered = filtered.filter(shipment =>
                    !shipment.shipment.comments || shipment.shipment.comments.length === 0
                );
            } else {
                filtered = filtered.filter(shipment =>
                    shipment.shipment.comments?.some(comment => comment.commenter_name === selectedCommenter)
                );
            }
        }

        if (selectedOrderType) {
            filtered = filtered.filter(shipment => shipment.shipment.order_details.order_type === selectedOrderType);
        }

        setFilteredShipments(filtered);
    };

    const handleViewDetails = async (trackingId) => {
        try {
            const data = await fetchShipmentDetails(trackingId);
            setShipmentDetails(data);
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching shipment details:', error);
        }
    };

    const handleAddComment = async () => {
        if (!newComment.trim()) {
            alert('Please enter a comment');
            return;
        }
        try {
            const comment = {
                comment_text: newComment,
                comment_date_time: new Date().toISOString(),
                commenter_name: user?.name || 'Admin',
            };
            await addComment(shipmentDetails.awb_no, comment);
            setNewComment('');
            handleViewDetails(shipmentDetails.awb_no);
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        fetchShipmentsData();
    };

    const handleExport = async () => {
        try {
            const data = await fetchShipments();
            const csv = jsonToCsv(data);
            downloadCsv('shipments.csv', csv);
        } catch (error) {
            console.error('Error exporting data:', error);
        }
    };

    const handleRefreshAll = async () => {
        try {
            const data = await fetchShipments();
            const trackingIds = data.map((shipment) => shipment.tracking_id);
            await fetchAllShipmentDetails(trackingIds);
            fetchShipmentsData();
        } catch (error) {
            console.error('Error refreshing data:', error);
        }
    };

    return (
        <div className="shipment-page aerial-dashboard">
            <div className="control-tower-header">
                <h2>Shipments</h2>
                <div className="control-buttons">
                    <Button className="control-btn refresh-btn" onClick={handleRefreshAll}>
                        Refresh All
                    </Button>
                    <Button className="control-btn export-btn" onClick={handleExport}>
                        Export Data
                    </Button>
                </div>
            </div>

            {/* Collapsible Filter Section */}
            <div className={`filter-section ${isFilterOpen ? 'open' : ''}`}>
                <div className="filter-header" onClick={() => setIsFilterOpen(!isFilterOpen)}>
                    <h3>Filters</h3>
                    <span>{isFilterOpen ? '▲' : '▼'}</span>
                </div>
                <div className="filter-content">
                    <Form.Group className="filter-group">
                        <Form.Label>Order Type</Form.Label>
                        <Form.Select
                            value={selectedOrderType}
                            onChange={(e) => setSelectedOrderType(e.target.value)}
                        >
                            <option value="">All</option>
                            {orderTypeOptions.map((option) => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="filter-group">
                        <Form.Label>Logistic</Form.Label>
                        <Form.Select
                            value={selectedLogistic}
                            onChange={(e) => setSelectedLogistic(e.target.value)}
                        >
                            <option value="">All</option>
                            {logisticsOptions.map((option) => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="filter-group">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={dateRange.start}
                            onChange={(e) => setDateRange({ ...dateRange, start: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group className="filter-group">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={dateRange.end}
                            onChange={(e) => setDateRange({ ...dateRange, end: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group className="filter-group">
                        <Form.Label>Commenter</Form.Label>
                        <Form.Select
                            value={selectedCommenter}
                            onChange={(e) => setSelectedCommenter(e.target.value)}
                        >
                            <option value="">All</option>
                            {commenterOptions.map((option) => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </div>
            </div>

            {loading ? (
                <div className="sky-loading">
                    <div className="radar-spinner"></div>
                    <span>Loading Shipments Data...</span>
                </div>
            ) : (
                <>
                    <ShipmentSummary
                        shipments={filteredShipments}
                        logisticsCount={logisticsCount}
                        logisticsValue={logisticsValue}
                        totalValue={totalValue}
                    />
                    <ShipmentTable shipments={filteredShipments} onViewDetails={handleViewDetails} />
                    <ShipmentModal
                        show={showModal}
                        handleClose={handleCloseModal}
                        shipmentDetails={shipmentDetails}
                        newComment={newComment}
                        setNewComment={setNewComment}
                        handleAddComment={handleAddComment}
                    />
                </>
            )}
        </div>
    );
};

export default ShipmentPage;