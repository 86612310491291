/* react_frontend/src/pages/Store/OrdersPage.js */
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import './OrdersPage.css';

const OrdersPage = () => {
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [pageInfo, setPageInfo] = useState({ currentPage: 1, hasNextPage: false, hasPreviousPage: false, endCursor: null, startCursor: null });
    const [shopDomain, setShopDomain] = useState('');
    const [availableStores, setAvailableStores] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [editTags, setEditTags] = useState('');
    const [editNote, setEditNote] = useState('');
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [customTimeline, setCustomTimeline] = useState([]);
    const [newTimelineMessage, setNewTimelineMessage] = useState('');
    const [displayedPastOrders, setDisplayedPastOrders] = useState([]);
    const [pastOrderLimit, setPastOrderLimit] = useState(3);
    const [statusFilter, setStatusFilter] = useState('');
    const [customerStatusFilter, setCustomerStatusFilter] = useState('');
    const [expandedOrder, setExpandedOrder] = useState(null);

    useEffect(() => {
        const fetchStores = async () => {
            try {
                const response = await axios.get('/shopify/stores', { withCredentials: true });
                setAvailableStores(response.data.stores);
                if (response.data.stores.length > 0) setShopDomain(response.data.stores[0].shopDomain);
            } catch (err) {
                console.error('Error fetching stores:', err);
                setError('Failed to load stores.');
            }
        };
        fetchStores();
    }, []);

    useEffect(() => {
        if (shopDomain) fetchOrders();
    }, [shopDomain, currentPage, searchQuery]);

    useEffect(() => {
        const applyFilters = () => {
            let result = [...orders];
            if (statusFilter) {
                result = result.filter(order =>
                    order.status && typeof order.status === 'string' && order.status.toLowerCase() === statusFilter.toLowerCase()
                );
            }
            if (customerStatusFilter) {
                result = result.filter(order => {
                    const customerStatus = getCustomerStatus(order)?.text;
                    return customerStatus && customerStatus.toLowerCase() === customerStatusFilter.toLowerCase();
                });
            }
            setFilteredOrders(result);
        };
        applyFilters();
    }, [orders, statusFilter, customerStatusFilter]);

    const fetchOrders = async () => {
        setLoading(true);
        setError('');
        try {
            const params = { shopDomain, page: currentPage, after: currentPage > 1 ? pageInfo.endCursor : null, query: searchQuery.trim() || null };
            const response = await axios.get('/shopify/orders', { params, withCredentials: true });
            const sanitizedOrders = response.data.orders.map(order => ({
                ...order,
                status: order.status || 'UNKNOWN',
                paymentStatus: order.paymentStatus || 'UNKNOWN',
            }));
            setOrders(sanitizedOrders);
            setFilteredOrders(sanitizedOrders);
            setPageInfo(response.data.pageInfo);
            setSelectedOrders([]);
            setSelectAll(false);
        } catch (err) {
            setError('Failed to fetch orders: ' + (err.response?.data?.message || err.message));
            setOrders([]);
            setFilteredOrders([]);
        } finally {
            setLoading(false);
        }
    };

    const fetchOrderDetails = async (orderId) => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.get('/shopify/order', { params: { orderId, shopDomain }, withCredentials: true });
            const orderData = {
                ...response.data,
                status: response.data.status || 'UNKNOWN',
                paymentStatus: response.data.paymentStatus || 'UNKNOWN',
            };
            let parsedNote = '';
            let parsedCustomTimeline = [];
            if (orderData.note) {
                try {
                    const noteObj = JSON.parse(orderData.note);
                    parsedNote = noteObj.note || '';
                    parsedCustomTimeline = noteObj.customTimeline || [];
                } catch (e) {
                    parsedNote = orderData.note;
                    parsedCustomTimeline = orderData.customTimeline || [];
                }
            }
            const pastOrdersWithStatus = await Promise.all(
                orderData.pastOrders.slice(0, pastOrderLimit).map(async (order) => {
                    const fulfillmentResponse = await axios.get('/shopify/order/fulfillment', { params: { orderId: order.id, shopDomain }, withCredentials: true });
                    return { ...order, fulfillmentStatus: fulfillmentResponse.data.fulfillmentStatus || 'N/A' };
                })
            );
            setSelectedOrder({ ...orderData, pastOrders: orderData.pastOrders });
            setDisplayedPastOrders(pastOrdersWithStatus);
            setEditTags(orderData.tags ? orderData.tags.join(', ') : '');
            setEditNote(parsedNote);
            setCustomTimeline(parsedCustomTimeline);
            setShowModal(true);
        } catch (err) {
            setError('Failed to fetch order details: ' + (err.response?.data?.message || err.message));
        } finally {
            setLoading(false);
        }
    };

    const handleEditOrder = async () => {
        try {
            const tagsArray = editTags.split(',').map(tag => tag.trim()).filter(tag => tag);
            const updatedNote = JSON.stringify({ note: editNote, customTimeline });
            await axios.post(`/shopify/order/${selectedOrder.id}`, { shopDomain, tags: tagsArray, note: updatedNote, customTimeline }, { withCredentials: true });
            setShowModal(false);
            fetchOrders();
        } catch (err) {
            setError('Failed to update order: ' + (err.response?.data?.message || err.message));
        }
    };

    const handleStoreChange = (e) => {
        setShopDomain(e.target.value);
        setCurrentPage(1);
        setSearchQuery('');
        setOrders([]);
        setFilteredOrders([]);
        setPageInfo({ currentPage: 1, hasNextPage: false, hasPreviousPage: false, endCursor: null, startCursor: null });
    };

    const handleNextPage = () => pageInfo.hasNextPage && setCurrentPage(prev => prev + 1);
    const handlePrevPage = () => pageInfo.hasPreviousPage && setCurrentPage(prev => prev - 1);

    const handleSelectOrder = (orderId) => {
        setSelectedOrders(prev => prev.includes(orderId) ? prev.filter(id => id !== orderId) : [...prev, orderId]);
    };

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        setSelectedOrders(selectAll ? [] : filteredOrders.map(order => order.id));
    };

    const handleDownloadInvoices = async () => {
        if (selectedOrders.length === 0) {
            setError('Select orders to download invoices.');
            return;
        }
        setDownloading(true);
        setError('');
        try {
            const response = await axios.post('/shopify/orders/invoices', { shopDomain, orderIds: selectedOrders }, { withCredentials: true, responseType: 'blob' });
            const disposition = response.headers['content-disposition'];
            let filename = selectedOrders.length > 1 ? `Invoices_${Date.now()}.zip` : 'invoice.pdf';
            if (disposition && disposition.includes('filename=')) filename = disposition.split('filename=')[1].replace(/"/g, '');
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (err) {
            setError('Failed to download invoices: ' + (err.response?.data?.message || err.message));
        } finally {
            setDownloading(false);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const handleStatusFilterChange = (e) => setStatusFilter(e.target.value);
    const handleCustomerStatusFilterChange = (e) => setCustomerStatusFilter(e.target.value);

    const handleCustomTimelineChange = (index, field, value) => {
        const updatedEvents = [...customTimeline];
        updatedEvents[index][field] = value;
        setCustomTimeline(updatedEvents);
    };

    const addCustomTimelineEvent = () => {
        if (!newTimelineMessage.trim()) return;
        setCustomTimeline([...customTimeline, { message: newTimelineMessage, createdAt: new Date().toISOString() }]);
        setNewTimelineMessage('');
    };

    const handlePastOrderClick = (orderId) => fetchOrderDetails(orderId);

    const loadMorePastOrders = async () => {
        setLoading(true);
        try {
            const nextLimit = pastOrderLimit + 3;
            const nextOrders = selectedOrder.pastOrders.slice(pastOrderLimit, nextLimit);
            const additionalOrders = await Promise.all(
                nextOrders.map(async (order) => {
                    const fulfillmentResponse = await axios.get('/shopify/order/fulfillment', { params: { orderId: order.id, shopDomain }, withCredentials: true });
                    return { ...order, fulfillmentStatus: fulfillmentResponse.data.fulfillmentStatus || 'N/A' };
                })
            );
            setDisplayedPastOrders([...displayedPastOrders, ...additionalOrders]);
            setPastOrderLimit(nextLimit);
        } catch (err) {
            setError('Failed to load more past orders: ' + (err.response?.data?.message || err.message));
        } finally {
            setLoading(false);
        }
    };

    const getCustomerStatus = (order) => {
        const pastSignals = order.previousOrders || 0;
        const currentTotal = parseFloat(order.total?.split(' ')[0] || 0);
        const totalSpent = parseFloat(order.totalSpent?.split(' ')[0] || 0);
        if (pastSignals <= 1) return null;
        else if (pastSignals > 1 && currentTotal === totalSpent) return { text: 'RTO Risk', className: 'rto-chance' };
        else if (pastSignals > 1 && totalSpent > currentTotal) return { text: 'Loyal', className: 'old-customer' };
        return null;
    };

    const toggleExpand = (orderId) => setExpandedOrder(expandedOrder === orderId ? null : orderId);

    const statusOptions = ['', 'UNFULFILLED', 'FULFILLED', 'ON_HOLD', 'CANCELLED', 'UNKNOWN'];
    const customerStatusOptions = ['', 'RTO Risk', 'Loyal'];

    return (
        <div className="orders-page cosmic-voyage">
            <div className="stellar-header">
                <h3 className="nebula-title">Shipping Dashboard</h3>
                <div className="header-actions">
                    <span className="order-count">{filteredOrders.length} Orders</span>
                    {selectedOrders.length > 0 && (
                        <button className="hyperdrive-button" onClick={handleDownloadInvoices} disabled={downloading}>
                            {downloading ? <span className="warp-sequence">Generating...</span> : `Generate Invoice ${selectedOrders.length} (${selectedOrders.length})`}
                        </button>
                    )}
                </div>
            </div>

            <div className="starship-console">
                <div className="console-row">
                    <label className="console-label">Store:</label>
                    <select value={shopDomain} onChange={handleStoreChange} className="quantum-dropdown">
                        {availableStores.map(store => (
                            <option key={store.shopDomain} value={store.shopDomain}>{store.shopDomain}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="cosmic-radar">
                <div className="radar-row">
                    <input type="text" value={searchQuery} onChange={handleSearchChange} placeholder="Search by ID, Name, or Email" className="radar-beam" />
                    <div className="filter-controls">
                        <select value={statusFilter} onChange={handleStatusFilterChange} className="quantum-dropdown filter-dropdown">
                            {statusOptions.map(option => (
                                <option key={option || 'all'} value={option}>{option || 'All Statuses'}</option>
                            ))}
                        </select>
                        <select value={customerStatusFilter} onChange={handleCustomerStatusFilterChange} className="quantum-dropdown filter-dropdown">
                            {customerStatusOptions.map(option => (
                                <option key={option || 'all'} value={option}>{option || 'All Customers'}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            {loading ? (
                <div className="cosmic-storm">
                    <div className="vortex-spinner"></div>
                    <span>Scanning Orders...</span>
                </div>
            ) : error ? (
                <div className="distress-signal">
                    <span>{error}</span>
                    <button className="retry-button" onClick={fetchOrders}>Retry</button>
                </div>
            ) : filteredOrders.length === 0 ? (
                <p className="void-message">No Orders to Ship.</p>
            ) : (
                <div className="orbital-cluster">
                    <div className="asteroid-belt">
                        <label className="belt-control">
                            <input type="checkbox" checked={selectAll} onChange={handleSelectAll} /> Select All ({selectedOrders.length})
                        </label>
                    </div>
                    {filteredOrders.map(order => {
                        const customerStatus = getCustomerStatus(order);
                        const isExpanded = expandedOrder === order.id;
                        return (
                            <div
                                key={order.id}
                                className={`shipment-card ${isExpanded ? 'expanded' : ''}`}
                                onMouseEnter={() => toggleExpand(order.id)}
                                onMouseLeave={() => toggleExpand(order.id)}
                            >
                                <div className="card-main" onClick={() => fetchOrderDetails(order.id)}>
                                    <input
                                        type="checkbox"
                                        checked={selectedOrders.includes(order.id)}
                                        onChange={() => handleSelectOrder(order.id)}
                                        onClick={e => e.stopPropagation()}
                                    />
                                    <div className="order-info">
                                        <span className="comet-id">{order.name}</span>
                                        <span className="stardust-date">{new Date(order.date).toLocaleDateString()}</span>
                                    </div>
                                    <div className="customer-info">
                                        <span className="customer-name">{order.customerName || 'N/A'}</span>
                                        <span className="customer-email">{order.customerEmail || 'N/A'}</span>
                                    </div>
                                    <div className="status-info">
                                        <span className={`gravity-well ${order.status.toLowerCase()}`}>{order.status}</span>
                                        <span className={`payment-status ${order.paymentStatus.toLowerCase()}`}>
                                            {order.paymentStatus === 'PAID' ? 'Prepaid' : 'COD'}
                                        </span>
                                    </div>
                                    <div className="total-info">
                                        <span className="luminosity">{order.total}</span>
                                        {customerStatus && <span className={`customer-status ${customerStatus.className}`}>{customerStatus.text}</span>}
                                    </div>
                                </div>
                                <div className="card-details">
                                    <div className="detail-row">
                                        <span className="detail-label">Ship To:</span>
                                        <span className="detail-value">{order.shippingAddress || 'N/A'}</span>
                                    </div>
                                    <div className="detail-row">
                                        <span className="detail-label">Tags:</span>
                                        <span className="detail-value">{order.tags?.length > 0 ? order.tags.join(', ') : 'None'}</span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <div className="wormhole-nav">
                        <button className="jump-gate prev-gate" onClick={handlePrevPage} disabled={!pageInfo.hasPreviousPage || loading}>←</button>
                        <span className="sector-coordinate">{pageInfo.currentPage}</span>
                        <button className="jump-gate next-gate" onClick={handleNextPage} disabled={!pageInfo.hasNextPage || loading}>→</button>
                    </div>
                </div>
            )}

            <div className={`stellar-hologram ${showModal ? 'active' : ''}`}>
                <div className="holo-shield" onClick={() => setShowModal(false)}></div>
                <div className="holo-core">
                    <button className="eject-core" onClick={() => setShowModal(false)}>✕</button>
                    <h3 className="holo-signature">{selectedOrder?.name} - Shipping Details</h3>
                    {selectedOrder ? (
                        <div className="holo-matrix">
                            <div className="past-orders">
                                <h4>Past Shipments</h4>
                                {displayedPastOrders.length > 0 ? (
                                    <>
                                        <div className="past-orders-table">
                                            <div className="past-orders-header">
                                                <span>Order</span>
                                                <span>Date</span>
                                                <span>Total</span>
                                                <span>Status</span>
                                            </div>
                                            {displayedPastOrders.map(order => (
                                                <div
                                                    key={order.id}
                                                    className="past-order-row"
                                                    onClick={() => handlePastOrderClick(order.id)}
                                                    title="Click to view details"
                                                >
                                                    <span className="past-order-id">{order.name}</span>
                                                    <span className="past-order-date">
                                                        {new Date(order.date).toLocaleDateString()}
                                                    </span>
                                                    <span className="past-order-total">{order.total}</span>
                                                    <span className={`past-order-status ${order.status.toLowerCase()}`}>
                                                        {order.cancelledAt ? (
                                                            <span className="status-badge cancelled">Cancelled</span>
                                                        ) : order.fulfillmentStatus === 'DELIVERED' ? (
                                                            <span className="status-badge delivered">Delivered</span>
                                                        ) : order.tags?.includes('Undelivered') ? (
                                                            <span className="status-badge undelivered">Undelivered</span>
                                                        ) : (
                                                            order.status
                                                        )}
                                                        {order.fulfillmentStatus !== 'N/A' && order.fulfillmentStatus !== 'DELIVERED' && (
                                                            <span className="fulfillment-note">{order.fulfillmentStatus}</span>
                                                        )}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                        {pastOrderLimit < selectedOrder.pastOrders.length && (
                                            <button
                                                className="load-more-btn"
                                                onClick={loadMorePastOrders}
                                                disabled={loading}
                                            >
                                                {loading ? (
                                                    <span className="loading-dots">Loading...</span>
                                                ) : (
                                                    'Load More'
                                                )}
                                            </button>
                                        )}
                                    </>
                                ) : (
                                    <p className="no-past-orders">
                                        This is the customer’s first order. No shipping history yet.
                                    </p>
                                )}
                            </div>
                            <div className="tags-editor">
                                <h4>Tags</h4>
                                <input
                                    type="text"
                                    value={editTags}
                                    onChange={(e) => setEditTags(e.target.value)}
                                    placeholder="Order tags"
                                    className="frequency-input"
                                />
                            </div>
                            <div className="items-notes-card">
                                <div className="items-column">
                                    <h4>Items to Ship</h4>
                                    {selectedOrder.items?.map((item, index) => (
                                        <div key={index} className="cargo-unit">
                                            {item.imageUrl && <img src={item.imageUrl} alt={item.sku} className="cargo-beacon" />}
                                            <div className="item-details">
                                                <span><strong>SKU:</strong> {item.sku || 'N/A'}</span>
                                                <span><strong>Qty:</strong> {item.quantity || 0}</span>
                                                <span><strong>Price:</strong> {item.unitPrice}</span>
                                                <span><strong>Discount:</strong> {item.discount}</span>
                                            </div>
                                        </div>
                                    )) || <p>No items available.</p>}
                                </div>
                                <div className="notes-column">
                                    <h4>Order Notes</h4>
                                    {selectedOrder.note && selectedOrder.note.trim() && (
                                        <div className="note-entry">
                                            <span className="note-label">Note:</span>
                                            <span className="note-text">{selectedOrder.note}</span>
                                        </div>
                                    )}
                                    {selectedOrder.timeline
                                        ?.filter(event => event.id.includes('CommentEvent'))
                                        .map((event, index) => (
                                            <div key={index} className="note-entry">
                                                <span className="note-label">Comment ({new Date(event.createdAt).toLocaleString()}):</span>
                                                <span className="note-text">{event.message}</span>
                                            </div>
                                        ))}
                                    {(!selectedOrder.note || !selectedOrder.note.trim()) &&
                                        !selectedOrder.timeline?.some(event => event.id.includes('CommentEvent')) && (
                                            <p className="no-notes">No order-specific notes or comments.</p>
                                        )}
                                </div>
                            </div>
                            <div className="order-customer-card">
                                <div className="order-details-column">
                                    <h4>Order Details</h4>
                                    <div className="compact-summary">
                                        <span className="summary-label">Date:</span>
                                        <span>{new Date(selectedOrder.date).toLocaleString()}</span>
                                        <span className="summary-label">Total:</span>
                                        <span>{selectedOrder.total}</span>
                                        <span className="summary-label">Status:</span>
                                        <span className={`gravity-well ${selectedOrder.status.toLowerCase()}`}>{selectedOrder.status}</span>
                                        <span className="summary-label">Payment:</span>
                                        <span className={`payment-status ${selectedOrder.paymentStatus.toLowerCase()}`}>
                                            {selectedOrder.paymentStatus === 'PAID' ? 'Prepaid' : 'COD'}
                                        </span>
                                    </div>
                                </div>
                                <div className="customer-details-column">
                                    <h4>Customer Details</h4>
                                    <p><strong>Name:</strong> {selectedOrder.customerName || 'N/A'}</p>
                                    <p><strong>Email:</strong> {selectedOrder.customerEmail || 'N/A'}</p>
                                    <p><strong>Phone:</strong> {selectedOrder.customerPhone || 'N/A'}</p>
                                    <p><strong>Shipping Address:</strong> {selectedOrder.shippingAddress || 'N/A'}</p>
                                </div>
                            </div>
                            <div className="tags-tracking-card">
                                <div className="tags-column">
                                    <h4>Tags</h4>
                                    <input
                                        type="text"
                                        value={editTags}
                                        onChange={(e) => setEditTags(e.target.value)}
                                        placeholder="e.g., Urgent, Fragile"
                                        className="compact-frequency-input"
                                    />
                                </div>
                                <div className="tracking-column">
                                    <h4>Tracking</h4>
                                    {selectedOrder.trackingInfo && selectedOrder.trackingInfo.length > 0 ? (
                                        selectedOrder.trackingInfo.map((track, index) => (
                                            <p key={index} className="beacon-signal">
                                                <strong>{track.company}:</strong> <a href={track.url} target="_blank" rel="noopener noreferrer">{track.number}</a>
                                            </p>
                                        ))
                                    ) : (
                                        <p className="no-beacon">Not Shipped Yet</p>
                                    )}
                                </div>
                            </div>
                            <div className="chrono-stream">
                                <h4>Shipping Notes</h4>
                                {customTimeline.map((event, index) => (
                                    <div key={index} className="chrono-entry">
                                        <span className="time-warp">{new Date(event.createdAt).toLocaleString()}</span>
                                        <input
                                            type="text"
                                            value={event.message}
                                            onChange={(e) => handleCustomTimelineChange(index, 'message', e.target.value)}
                                            className="signal-input"
                                        />
                                    </div>
                                ))}
                                <div className="chrono-transmitter">
                                    <input
                                        type="text"
                                        value={newTimelineMessage}
                                        onChange={(e) => setNewTimelineMessage(e.target.value)}
                                        placeholder="Add shipping note (e.g., Packed, Shipped)"
                                        className="signal-input"
                                    />
                                    <button onClick={addCustomTimelineEvent} className="transmit-pulse">Log</button>
                                </div>
                            </div>
                            {error && <div className="distress-call">{error}</div>}
                            <button className="engage-thrusters" onClick={handleEditOrder}>Update</button>
                        </div>
                    ) : (
                        <p className="holo-static">Loading Details...</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OrdersPage;